<template>
  <!-- 键行 -->
  <div class="decisionMake">
    <div class="left">
      <!-- 头部固定内容 -->
      <div class="topBox">
        <backtitle lable="融资决议管理"></backtitle>
        <div class="header">
          <title-template
            :name="'供应商：' + (finBusuinessInfo.gysCompanyName || '')"
          >
            <template slot="operation">
              <div style="flex: 1"></div>
              <div class="item" style="flex: 1; min-width: 900px">
                <font style="color: #f56c6c" v-if="dictType === '1'">*</font>
                <span v-if="dictType === '1'">融资模式：</span>
                <base-select
                  v-model="formData.financeType"
                  style="width: 150px; margin-right: 10px"
                  :options="getDictLists('FINANCING_MODEL')"
                  :selectedField="['dictId', 'dictName']"
                  @change="changeFinanceType"
                  v-if="dictType === '1'"
                  :disabled="isDetail"
                ></base-select>
                <font style="color: #f56c6c">*</font>{{ topdateType() }}
                <el-date-picker
                  v-model="formData.meettingDate"
                  :picker-options="startDatePicker"
                  placeholder="请选择"
                  type="date"
                  :value-format="'yyyy-MM-dd'"
                  style="width: 150px"
                  :disabled="isDetail"
                  @change="changePreLoanDate"
                ></el-date-picker>
                <font style="color: #f56c6c;margin-left: 10px;">*</font>预计放款时间
                <el-date-picker
                  v-model="formData.preLoanDate"
                  :picker-options="pickerOptions"
                  placeholder="请选择"
                  type="date"
                  :value-format="'yyyy-MM-dd'"
                  style="width: 150px; margin-right: 10px"
                  :disabled="isDetail"
                ></el-date-picker>
                <base-button
                  label="审核履历"
                  icon="iconfont iconzuo"
                  style="float: right"
                  @click="drawervisible = true"
                  class="examine"
                ></base-button>
              </div>
            </template>
          </title-template>
        </div>
        <div class="topTabs">
          <div class="tabs">
            <div
              v-for="(item, index) in tabsTitles"
              :key="index"
              @click="clicTabs(index)"
              :class="{ activeClass: showMain === index }"
            >
              <div class="reg"></div>
              <div class="tipsImg" v-if="item.showTips">
                <img :src="successImg" v-if="item.pass" />
                <img :src="errorImg" v-if="!item.pass" />
              </div>
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
      <!-- 主要内容 -->
      <div class="contentBox" :id="scrollBoxId">
        <div v-show="showMain === 0">
              <div data-title="融资决议概述" class="modelBox">
            <text-title index="01" label="融资决议概述" :required="true"></text-title>
            <base-input
              type="textarea"
              :placeholder="placeholder1"
              v-model="finResultSummary"
              :restrict="false"
              rows="10"
              maxlength="2000"
              style="margin-top: 10px"
              :disabled="isDetail"
            ></base-input>
          </div>
          <div data-title="借款人信息" class="modelBox">
            <text-title index="02" label="借款人信息">
                <template slot="right">
                  <span style="font-weight:400;font-size:20px">融资金额合计(元):{{ totalAmount ?totalAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-'}}</span>
              </template>
            </text-title>
            <financing-plan
              :mainPartData="mainPartData"
              :payeeData="payeeData"
              ref="financingData"
              :businessId="businessId"
              :detailData="detailData"
              :formData="formData"
              :schemeInfoList="schemeInfoList"
              :pledgorList="pledgorList"
              :dictType="dictType"
              @clearRepaymentPlan="clearRepaymentPlan"
              @clearShareholderMeeting="clearShareholderMeeting"
              @addSecondBorrower='addSecondBorrower'
              @delSecondBorrower='delSecondBorrower'
              @borrowerChange='borrowerChange'
              :index='"02"'
              :disabled="isDetail"
            ></financing-plan>
          </div>
          <div data-title="融资方案" class="modelBox">
            <text-title index="03" label="融资方案">
            </text-title>
            <!-- 融资方案 -->
            <financing-plan
              :mainPartData="mainPartData"
              :payeeData="payeeData"
              ref="newFinancingData"
              :businessId="businessId"
              :detailData="detailData"
              :formData="formData"
              :schemeInfoList="schemeInfoList"
              :pledgorList="pledgorList"
              :dictType="dictType"
              @clearRepaymentPlan="clearRepaymentPlan"
              @clearShareholderMeeting="clearShareholderMeeting"
              :index='"03"'
              :disabled="isDetail"
            ></financing-plan>
          </div>
          <div data-title="质押信息" class="modelBox">
            <text-title
              index="04"
              label="质押信息"
              :titleTips="
                formData.financeType === 'ONCE_MODEL'
                  ? '与深圳稳诚—方签署【应收账款最高额质押反担保合同】'
                  : '签署【应收账款最高额质押反担保合同】'
              "
              :required="true"
            ></text-title>
            <!-- 质押信息 -->
            <pledge-info
              :pledgorList="pledgorList"
              :contractInfoData="contractInfoData"
              :formData="formData"
              @clearRepaymentPlan="clearRepaymentPlan"
              :isDetail="isDetail"
            ></pledge-info>
          </div>
          <div data-title="担保措施" class="modelBox">
            <text-title index="05" label="担保措施"></text-title>
            <div class="measure">
              <!-- 个人担保 -->
              <personal-guarantee
                :personalSelect="personalSelect"
                :mainPartData="mainPartData"
                :businessId="businessId"
                @changePersonalData="changePersonalData"
                :personalData="personalData"
                :finBusuinessInfo="finBusuinessInfo"
                :schemeInfoList="schemeInfoList"
                :subject.sync="personalSubject"
                :dictType="dictType"
               :financeType='formData.financeType'
                :finaaceProductName='finaaceProductName'
                :isDetail="isDetail"
              ></personal-guarantee>
              <!-- 企业担保 -->
              <enterprise-guarantee
                :enterpriseSelect="enterpriseSelect"
                :mainPartData="mainPartData"
                :enterpriseData="enterpriseData"
                :financeType='formData.financeType'
                @changeEnterpriseData="changeEnterpriseData"
                :subject.sync="enterpriseSubject"
                :dictType="dictType"
                :isDetail="isDetail"
              ></enterprise-guarantee>
            </div>
          </div>
          <div data-title="股东会决议有效性" class="modelBox">
            <!-- 股东会决议有效性 -->
            <text-title
              index="06"
              label="股决/董决有效性"
              titleTips="供应商对本次融资行为出具《股东会决议/董事会决议》"
              :required="true"
            ></text-title>
            <div>
              <shareholder-meeting-resolution
                ref="shareholderData"
                :singleTransactionType="singleTransactionType"
                @getValidInfo="getValidInfo"
                :resultValidTimeType="resultValidTimeType"
                :shareholderResultValidInfo="shareholderResultValidInfo"
                :gysId="finBusuinessInfo.gysId"
                @changeSingleTransactionType="changeSingleTransactionType"
                @changeShareholderMeetingResolution="
                  changeShareholderMeetingResolution
                "
                @changeResolutionFrom="changeResolutionFrom"
                @changeValidTimeUnit="changeValidTimeUnit"
                :financeAmount="totalAmount"
                :resultValidTimeFileType='resultValidTimeFileType'
                :disabled="isDetail"
              />
            </div>
          </div>
          <div data-title="风控措施" class="modelBox">
            <text-title
              index="07"
              label="风控措施"
              :titleTips="
                formData.financeType === 'ONCE_MODEL'
                  ? '供应商与稳惠保理签署《保理服务合同》，由稳惠保理受让供应商融资项目项下资金方未转让部分的应收账款'
                  : ''
              "
            ></text-title>
            <!-- 监管账户和保理服务信息 -->
            <account-and-service
              :accountData="accountData"
              ref="accountAndService"
              :serviceData="serviceData"
              :dictType="dictType"
              :gysId="finBusuinessInfo.gysId"
              :finRepayBankAccountInfoDTOs="finRepayBankAccountInfoDTOs"
              :schemeInfoList='schemeInfoList'
              @addSelectAccount="addSelectAccount"
              @changeFinRepayBankAccountInfoDTO="
                changeFinRepayBankAccountInfoDTO
              "
              :formData="formData"
              :isDetail="isDetail"
            ></account-and-service>
            <!-- 转让信息 -->
            <transfer-info
              :pledgorList="pledgorList"
              :alienatorData="alienatorData"
              :contractInfoData="contractInfoData"
              :finBusuinessInfo="finBusuinessInfo"
              @clearRepaymentPlan="clearRepaymentPlan"
              :isDetail="isDetail"
            ></transfer-info>
            <!-- 补充合同信息 -->
            <supplemental-contract-info
              :businessId="businessId"
              :contractInfoData="contractInfoData"
              :pledgorList="pledgorList"
              :alienatorData="alienatorData"
              :finBusuinessInfo="finBusuinessInfo"
              :isDetail="isDetail"
            ></supplemental-contract-info>
          </div>
          <div data-title="签署承诺函及委托付款函" class="modelBox">
            <text-title
              index="08"
              label="签署承诺函及委托付款函"
              :required="true"
            ></text-title>
            <!-- 签署承诺函 -->
            <!-- <base-input
              type="textarea"
              placeholder="请输入"
              v-model="commitmentLetter"
              rows="5"
            ></base-input> -->
            <letter-of-commitment
              :signatory.sync="signatory"
              :factoring.sync="factoring"
              :finContractInfoNames.sync="finContractInfoNames"
              :dictType="dictType"
              :isDetail="isDetail"
            />
          </div>
          <div data-title="还款计划" class="modelBox">
            <text-title index="09" label="还款计划" :required="true">
              <template slot="right" v-if="!isDetail">
                <base-button
                  label="设置还款方式"
                  @click="setPlan"
                  v-if="this.schemeInfoList[0].repayTypeCode === '1'"
                />
                <base-button
                  label="生成"
                  icon="iconfont iconshengcheng"
                  @click="generateRepaymentPlan"
                />
              </template>
            </text-title>
            <repayment-plan
              @generateRepaymentPlan="generateRepaymentPlan"
              :finRepayPlan="finRepayPlan"
              :repaymentPlanContract="repaymentPlanContract"
              :isDetail="isDetail"
            />
          </div>
        </div>
        <div v-show="showMain === 1">
          <!-- <div data-title="评审会问题及落实" class="modelBox">
            <text-title index="10" label="评审会问题及落实"></text-title> -->
            <!-- 评审会问题及落实 -->
            <!-- <base-input
              type="textarea"
              placeholder="请输入"
              v-model="juryQuestionAnswer"
              rows="5"
              maxlength="500"
            ></base-input> -->
            <!-- <review-meeting
              :meetingSummaryDetailVOList="meetingSummaryDetailVOList"
            />
          </div> -->
          <div data-title="贷后管理措施" class="modelBox">
            <text-title index="10" label="贷后管理措施" :required="true"></text-title>
            <!-- 贷后管理措施 -->
            <base-input
              type="textarea"
              placeholder="请输入"
              v-model="afterLoanMethod"
              rows="5"
              maxlength="2000"
              style="margin-top: 10px"
              :disabled="isDetail"
            ></base-input>
          </div>
          <div data-title="其他备注" class="modelBox">
            <text-title index="11" label="其他备注"></text-title>
            <!-- 其他备注 -->
            <other-note
              ref="otherData"
              :dictType="dictType"
              :formData="formData"
              :lendingBeforeCondition.sync="lendingBeforeCondition"
              :lendingAfterArrange.sync="lendingAfterArrange"
              :otherNoteRemark.sync="otherNoteRemark"
              :finPawnInfos="finPawnInfos"
              :finResultInfoAssociatedAccountVOList="
                finResultInfoAssociatedAccountVOList
              "
              :isDetail="isDetail"
            ></other-note>
          </div>

          <div data-title="文件生成与下载" class="modelBox">
            <text-title index="12" label="文件生成与下载"></text-title>
            <base-form
              :componentList="otherFile"
              :formAttrs="{
                model: fileFormData,
                labelWidth: '100px',
              }"
              :showBtns="false"
              class="formData"
              ref="otherFile"
            >
            </base-form>
          </div>
        </div>
      </div>
    </div>
    <!-- 审核履历 -->
    <drawer
      :visible.sync="drawervisible"
      auditType="RZJY"
      name="审核履历"
      style="width: 480px; right: 0px"
      :dataList="dataList"
      :auditInfo="auditInfo"
    ></drawer>
    <!-- 底部按钮组 -->
    <div class="footer">
      <template v-if="!isDetail">
        <base-button label="保 存" @click="saveData('save')"></base-button>
        <base-button label="提 交" @click="saveData('')"></base-button>
      </template>
      <base-button label="关 闭" type="default" @click="close"></base-button>
    </div>
    <pre-view
      :fileId="generatefileId"
      :isOpen="true"
      :fileType="fileType"
      :count="count"
    />
    <!-- 右侧楼层 -->
    <scroll-fool
      ref="scrollFool"
      :scrollData="scrollData"
      :scrollBoxId="scrollBoxId"
    />
  </div>
</template>

<script>
// import ReviewMeeting from '../components/review-meeting.vue'
import RepaymentPlan from '../components/repayment-plan.vue'
import LetterOfCommitment from '../components/letter-of-commitment.vue'
import ShareholderMeetingResolution from '../components/shareholder-meeting-resolution.vue'
import ScrollFool from '@/components/packages/scroll-fool/scroll-fool.vue'
import PreView from '@/components/pre-view/pre-view.vue'
import BaseInput from '@/components/input/sz-input/sz-input.vue'
import drawer from '@/pages/business/components/drawer.vue'
import baseButton from '@/components/common/button/base-button/base-button.vue'
import BaseForm from '@/components/common/base-form//base-form.vue'
import { schemeInfo, factoringRelated, otherFile } from '../utils/config'
import { resolutionAPi } from '@/api/businessApi'
import TitleTemplate from '../../components/titleTemplate.vue'
import TextTitle from '@/components/packages/text-title/text-title.vue'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import { getDictLists } from '@/filters/fromDict'
import { contactApi } from '@/api/contactApi'
import Backtitle from '../../components/backtitle.vue'
import { formatDate } from '@/utils/auth/common'
import { allSupportFileTypes } from '@/components/pre-view/pre-view'
// import previewDialog from '../components/preview-dialog.vue'
import FinancingPlan from '../components/new-financing-plan.vue'
import PersonalGuarantee from '../components/personal-guarantee.vue'
import EnterpriseGuarantee from '../components/enterprise-guarantee.vue'
import PledgeInfo from '../components/pledge-info.vue'
import AccountAndService from '../components/account-and-service.vue'
import TransferInfo from '../components/transfer-info.vue'
import SupplementalContractInfo from '../components/supplemental-contract-info.vue'
import OtherNote from '../components/other-note.vue'
export default {
  name: 'decisionMake',
  props: {
    dictType: String
  },
  components: {
    // ReviewMeeting,
    BaseInput,
    RepaymentPlan,
    LetterOfCommitment,
    ShareholderMeetingResolution,
    ScrollFool,
    drawer,
    baseButton,
    BaseForm,
    TitleTemplate,
    TextTitle,
    BaseSelect,
    Backtitle,
    FinancingPlan,
    PersonalGuarantee,
    EnterpriseGuarantee,
    PledgeInfo,
    AccountAndService,
    TransferInfo,
    SupplementalContractInfo,
    OtherNote,

    PreView
  },
  data () {
    return {
      totalAmount: 0,
      costPrice: '',
      finaaceProductName: '',
      finResultSummary: '', // 融资决议概述
      placeholder1:
        '请输入：' +
        '\n' +
        '【示例】' +
        '\n' +
        '1.XXXXX此次申请稳时付业务金额XX万，资金渠道为XX，融资利率为X%，综合质押率为X%，XXX为甲方推荐供应商，融资项目均为XXXX项目，融资业务符合XXXXXXX纾困优惠政策范围；' +
        '\n' +
        '2.融资项目1：XXXXX项目，金地占股X%，合作方XX持股X%； ' +
        '\n' +
        '融资项目2：XXX项目，金地占股X%，合作方XX占股X%；' +
        '\n' +
        '两个融资项目法人均为XX；金地均拥有项目并表选择权，据财务经理XX、XX介绍，项目XXXX，XX；XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX； ' +
        '\n' +
        '3.两个融资项目均为XX付款，XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX；' +
        '\n' +
        '4.建设进度与销售情况：XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX；' +
        '\n' +
        '5.已与金地同事就通知合资方事项达成一致，在放款落实要素中，按要求完成邮件通知动作，抄送合作方领导，并回收《应收账款债权转让通知 书收讫函》用印版。 ' +
        '\n' +
        '以下为融资决议正文：',
      scrollBoxId: 'RZJYscroll',
      otherNoteRemark: '',
      lendingAfterArrange: '',
      lendingBeforeCondition: '',
      finResultInfoAssociatedAccountVOList: [], // 已控制关联资金路径的其他账户
      signatory: '',
      factoring: '',
      finContractInfoNames: '',
      resultValidTimeFileType: '0', // 有效性信息文件类型 0.股东会决议或股东决定(默认) 1.董事会决议 2.全选
      meetingSummaryDetailVOList: [], // 评审会问题及落实情况
      finPawnInfos: [], // 抵质押物
      finContractInfosCapitalReq: [], // 资金方要求
      afterLoanMethod: '',
      repaymentPlanContract: [], // 还款计划合同
      finRepayPlan: [], // 还款计划表格数据
      resultValidTimeType: '0', // 股东会决议有效性类型
      shareholderResultValidInfo: {
        resultValidTimeStart: '',
        resultValidTimeEnd: '',
        guaranteeQuota: '',
        busiApproveTime: '',
        validTimeNum: '',
        validTimeUnit: '',
        keyId: '',
        surplusGuaranteeQuota: '',
        validRemark: ''
      }, // 股东会决议有效性信息DTO
      shareholderResultValidInfos: {
        resultValidTimeStart: '',
        resultValidTimeEnd: '',
        guaranteeQuota: '',
        busiApproveTime: '',
        validTimeNum: '',
        validTimeUnit: '',
        keyId: '',
        surplusGuaranteeQuota: '',
        validRemark: ''
      },
      singleTransactionType: '1', // 非单笔默认值
      showMain: 0,
      scrollData: [],
      successImg: require('@/assets/success.png'),
      errorImg: require('@/assets/error.png'),
      tabsTitles: [
        { label: '融资基础信息', pass: true, showTips: false },
        { label: '其他放款条件', pass: true, showTips: false }
      ],
      finRepayBankAccountInfoDTOs: [{}],
      scrollTop: 0,
      commitmentLetter: '',
      enterpriseSubject: [],
      personalSubject: [],
      count: 0,
      fileType: '',
      previsible: false,
      generatefileId: '',
      fileFormData: {},
      pass: false,
      active: 0,
      drawervisible: false,
      formData: {
        financeType: '',
        signType: '',
        suppliersSealType: '',
        meettingDate: '',
        preLoanDate: '',
        remark: ''
      },
      pledgorList: [], // 质押合同数据
      accountData: { account: '', remark: '' },
      serviceData: {},
      activeNames: ['1', '2', '3', '4', '5', '6'],
      schemeInfoList: [{ serviceRateStr: '', serviceRateState: false }],
      finBusuinessInfo: {},
      personalData: [],
      enterpriseData: [],
      alienatorData: [],
      contractInfoData: [],
      detailData: {},
      auditInfo: {},
      dataList: [],
      businessId: '',
      keyId: '',
      instanceId: '',
      personsDatas: [],
      pickerOptions: {},
      startDatePicker: {},
      scrollxTime: null,
      mainPartData: [],
      payeeData: [],
      personalSelect: '', // 个人申请类型
      enterpriseSelect: '', // 企业申请类型
      secondDefaultEnterpriseData: {} // 二级模式下默认的企业担保信息
    }
  },
  computed: {
    otherFile () {
      return otherFile(this)
    },
    api () {
      return resolutionAPi
    },
    schemeInfoForm () {
      return function (index) {
        return schemeInfo(this, index)
      }
    },
    factoringRelatedForm () {
      return factoringRelated(this)
    },
    getDictLists () {
      return getDictLists
    }
  },
  created () {
    const self = this
    this.businessId = this.$route.query.keyId
    this.instanceId = this.$route.query.instanceId

    this.pickerOptions = {
      disabledDate (v) {
        // 临时解除不能选择当前日期之前日期
        // return v.getTime() < new Date().getTime() - 86400000
        let disabledTime = new Date('1899-12-31')
        if (self.formData.meettingDate) {
          disabledTime = new Date(self.formData.meettingDate)
        }
        return v.getTime() <= disabledTime
      }
    }
    this.startDatePicker = {
      disabledDate (time) {
        const disabledTime = new Date('1999-12-31')
        return time.getTime() <= disabledTime || time.getTime() >= new Date('2099-12-31')
      }
    }
  },
  mounted () {
    this.listbylabel()
    this.$nextTick(() => {
      // 获取资金方类型
      this.detail()
      // 初始化楼层数据 默认点击
      this.clicTabs(0)
      window.addEventListener('scroll', this.handleScrollx, true)
    })
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScrollx, false)
  },
  watch: {
    // alienatorData: {
    //   handler (val) {
    //     console.log(val, 222)
    //     const projectDebtorArr = []
    //     val.forEach((item) => {
    //       if (item.projectDebtor) {
    //         projectDebtorArr.push(item.projectDebtor)
    //       }
    //     })
    //     console.log(projectDebtorArr, 'projectDebtorArr')
    //     if (projectDebtorArr.length === 1) {
    //       this.finContractInfoNames = projectDebtorArr[0]
    //     } else if (projectDebtorArr.length > 1) {
    //       let St = ''
    //       projectDebtorArr.forEach((item, index) => {
    //         if (index === 0) {
    //           St = item
    //         } else if (index !== projectDebtorArr.length - 1 && index !== 0) {
    //           St = St + '、' + item
    //         } else {
    //           St = St + '和' + item
    //         }
    //       })
    //       this.finContractInfoNames = St
    //     }
    //   },
    //   deep: true
    // },
    'accountData.account': {
      handler (val) {
        this.$nextTick(() => {
          // 清空表单验证提示
          // this.$refs.accountAndService.clearFromValidate()
        })
      },
      deep: true
    },
    'serviceData.capitalCost': {
      handler (val) {
        if (!val) {
          val = 0
        }
        this.$set(
          this.serviceData,
          'yearRate',
          JSON.parse(
            JSON.stringify(
              (parseFloat(val) * 100 +
                parseFloat(
                  this.serviceData.serviceRate
                    ? this.serviceData.serviceRate
                    : 0
                ) *
                  100) /
                100
            )
          )
        )
      },
      deep: true
    },
    'serviceData.serviceRate': {
      handler (val) {
        if (!val) {
          val = 0
        }
        this.$set(
          this.serviceData,
          'yearRate',
          JSON.parse(
            JSON.stringify(
              (parseFloat(val) * 100 +
                parseFloat(
                  this.serviceData.capitalCost
                    ? this.serviceData.capitalCost
                    : 0
                ) *
                  100) /
                100
            )
          )
        )
      },
      deep: true
    },
    schemeInfoList: {
      handler (val) {
        let num = 0
        // 保理融资金额=融资本金（1+成本价格）
        val.forEach((ele) => {
          if (ele.financeAmount) {
            num = num + (parseFloat(ele.financeAmount))
            this.totalAmount = num
          }
        })

        if (val[0].costPrice) {
          num = num * (1 + (val[0].costPrice) / 100)
          if (!this.pass) {
            if (num !== 0) {
              this.$set(
                this.serviceData,
                'factoryAmount',
                parseFloat(num).toFixed(2)
              )
            }
          }
        }
      },
      deep: true
    },
    pledgorList: {
      // 一级质押信息赋值给转让信息
      handler (val) {
        if (!this.pass) {
          if (val.length > 0) {
            this.alienatorData = JSON.parse(JSON.stringify(val[0].pledgorData))
          }
        }
      },
      deep: true
    },
    scrollData: {
      handler (val) {
        if (val) {
          this.$nextTick(() => {
            this.$refs.scrollFool.getOptions()
          })
        }
      },
      deep: true
    }
  },
  methods: {
    // 借款人变更，相应变更对应地放款账户信息
    borrowerChange (data, arr, index) {
      this.finRepayBankAccountInfoDTOs.splice(index, 1, {})
    },
    // 增加借款人
    addSecondBorrower (data) {
      const obj = {
        account: '',
        accountNo: '',
        accountType: 0,
        bankId: '',
        bankName: '',
        enterpriseId: '',
        keyId: '',
        memo: ''
      }
      this.finRepayBankAccountInfoDTOs.push(obj)
    },
    // 删除借款人
    delSecondBorrower (data, index) {
      this.finRepayBankAccountInfoDTOs.splice(index, 1)
    },
    // 头部文字样式
    topdateType () {
      console.log(this.schemeInfoList[0], 'this.schemeInfoList[0]')
      if (this.schemeInfoList[0].finaaceProductName === '稳时付') {
        return '申请日期'
      } else {
        return '上会日期'
        // if (this.schemeInfoList[0].financeAmount - 5000000 > 0) {

        // } else {
        //   return '申请日期'
        // }
      }
    },

    // 设置还款方式
    setPlan () {
      console.log(this.$refs.financingData.$refs.repeatSchemeFormData)
      this.$refs.newFinancingData.$refs.repeatSchemeFormData.$refs.repayTypeCode.show()
    },
    // 记录数据验证信息
    errMsg (index, key) {
      this.$set(this.tabsTitles[index], 'pass', key)
      this.$set(this.tabsTitles[index], 'showTips', true)
    },
    clicTabs (index) {
      this.showMain = index
      let scrollData = []
      if (this.showMain === 0) {
        scrollData = [
          { label: '融资决议概述', id: 0 },
          { label: '借款人信息', id: 1 },
          { label: '融资方案', id: 2 },
          { label: '质押信息', id: 3 },
          { label: '担保措施', id: 4 },
          { label: '股东会决议有效性', id: 5 },
          { label: '风控措施', id: 6 },
          { label: '签署承诺函', id: 7 },
          { label: '还款计划', id: 8 }
        ]
      } else if (this.showMain === 1) {
        scrollData = [
          // { label: '评审会问题及落实', id: 9 },
          { label: '贷后管理措施', id: 9 },
          { label: '其他备注', id: 10 },
          { label: '文件生成与下载', id: 11 }
        ]
      }
      this.scrollData = scrollData
    },
    // 重置还款计划
    clearRepaymentPlan () {
      this.finRepayPlan = []
    },
    // 生成还款计划
    generateRepaymentPlan () {
      // 验证融资方案信息是否完整
      let state = true
      const schemeForm = this.$refs.financingData.$refs.schemeFormData

      for (let i = 0; i < schemeForm.length; i++) {
        schemeForm[i].validate((valid) => {
          if (!valid) {
            state = false
          }
        })
      }

      console.log(this.$refs.financingData)

      this.$refs.newFinancingData.$refs.repeatSchemeFormData.validate((valid) => {
        if (!valid) {
          state = false
        }
      })

      if (!state) {
        this.warning('请完善融资方案信息')
        return false
      }
      // 验证还款方式是否完整
      let repayAmountPass = false
      console.log(this.schemeInfoList, 'this.schemeInfoList')
      this.schemeInfoList.forEach((ele) => {
        ele.repayTime = this.getRepayTime(ele)
        if (ele.repayTypeCode === '0' || ele.repayTypeCode === '2') {
          // 一次性还本付息 || 一次性还本按月付息
          ele.finRepayStyleDTO.repayTime = ele.repayTime
          ele.chargeParty = ele.mainBorrower
        } else if (ele.repayTypeCode === '1') {
          // 分批还款
          if (
            !ele.finRepayStyleDTO.repayAmount &&
            !ele.finRepayStyleDTO.repayRate
          ) {
            repayAmountPass = true
          }
          ele.finRepayStyleDTO.chargeParty = ele.mainBorrower
          ele.repayStatus = ele.finRepayStyleDTO.repayStatus
          ele.repayAmount = ele.finRepayStyleDTO.repayAmount
          ele.chargeParty = ele.mainBorrower
        }
        if (!ele.serviceRateState) {
          ele.finServiceCollectInfo = []
          ele.serviceRate = parseFloat(ele.serviceRateStr)
        } else {
          ele.serviceRate = ''
        }
      })
      if (repayAmountPass) {
        this.warning('请完善分批还款信息')
        return false
      }
      // 验证转让合同信息是否完整
      let alienatorStatus = true
      let errorMsg = ''
      if (this.alienatorData.length === 0) {
        alienatorStatus = false
        errorMsg = '请完善质押信息'
      }
      console.log(this.alienatorData, '222')
      this.alienatorData.forEach((ele) => {
        if (
          !ele.finContractId ||
          !ele.finContractName ||
          (!ele.pledgeValue && ele.pledgeValue !== 0) ||
          (!ele.relLoanAmount && ele.relLoanAmount !== 0)
        ) {
          // !ele.pledgeRate
          alienatorStatus = false
          errorMsg = '请完善转让合同信息'
          // console.log(
          //   !ele.finContractId,
          //   !ele.finContractName,
          //   !ele.pledgeValue && ele.pledgeValue !== 0,
          //   !ele.relLoanAmount && ele.relLoanAmount !== 0,
          //   !ele.pledgeRate,
          //   '去去去'
          // )
          if (ele.pledgeRate === 0 || ele.pledgeRate === '0') {
            // errorMsg = '资金方质押率不能为0'
            errorMsg = '质押率需大于0'
          }
        }
      })

      if (!alienatorStatus) {
        alienatorStatus = true
        return this.warning(errorMsg)
      }
      // 验证转让信息是否完整

      if (this.alienatorData.length === 0) {
        alienatorStatus = false
      }
      this.alienatorData.forEach((ele) => {
        if (
          !ele.finContractId ||
          !ele.finContractName ||
          (!ele.pledgeValue && ele.pledgeValue !== 0) ||
          (!ele.relLoanAmount && ele.relLoanAmount !== 0)
        ) {
          //! ele.pledgeRate
          alienatorStatus = false
        }
      })
      if (!alienatorStatus) {
        alienatorStatus = true
        return this.warning('请完善转让信息')
      }
      // 已选合同数组
      const finContractIdArr = []
      this.alienatorData.forEach((item) => {
        finContractIdArr.push(item.finContractId)
      })
      // this.alienatorData.forEach(item => {
      //   finContractIdArr.push(item.finContractId)
      // })
      // 去重

      const newFinContractIdArr = [...new Set(finContractIdArr)]
      // 获取选择的合同并去重
      this.repaymentPlanContract = []
      console.log(newFinContractIdArr, this.pledgorList.contractData, this.pledgorList[0].contractData, 'finContractIdArr')
      if (this.pledgorList.contractData) {
        this.pledgorList.contractData.forEach((item) => {
          if (newFinContractIdArr.indexOf(item.keyId) >= 0) {
            this.repaymentPlanContract.push(item)
          }
        })
      } else if (this.pledgorList[0].contractData) {
        this.pledgorList[0].contractData.forEach((item) => {
          if (newFinContractIdArr.indexOf(item.keyId) >= 0) {
            this.repaymentPlanContract.push(item)
          }
        })
      }

      // 调用还款方式预览接口获取条数
      // this.finPlanInfos[0].finRepayStyleDTO 还款期数
      if (!this.schemeInfoList[0].repayTime) {
        this.warning('请填写预计放款时间')
        return
      }
      const advanceRepayTerm = this.schemeInfoList[0].financeTerm
      const str = this.formData.preLoanDate.replace(/-/g, '/')
      const yearItem =
        parseInt(advanceRepayTerm) >= 12
          ? parseInt(parseInt(advanceRepayTerm) / 12)
          : 0
      const monthItem =
        parseInt(advanceRepayTerm) < 12
          ? parseInt(advanceRepayTerm)
          : parseInt(advanceRepayTerm) % 12
      var date = new Date(str)
      var year = date.getFullYear() + yearItem
      var month = date.getMonth() + monthItem + 1
      if (month > 12) {
        year++
        month -= 12
      }
      if (month < 10) {
        month = '0' + month
      }
      var date2 = new Date(year, month, 0)
      var day1 = date.getDate()
      var day2 = date2.getDate()
      if (day1 > day2) {
        day1 = day2
      }
      if (day1 < 10) {
        day1 = '0' + day1
      }
      const endDate = year + '-' + month + '-' + day1
      let financeAmountAll = 0
      this.schemeInfoList.forEach((item) => {
        financeAmountAll = financeAmountAll + item.financeAmount * 1
      })
      if (
        this.schemeInfoList[0].finRepayStyleDTO.repayStatus === '0' ||
        this.schemeInfoList[0].finRepayStyleDTO.repayStatus === '2'
      ) {
        // 一次性还款调用预览接口

        this.finRepayPlan = [
          {
            preRepayDate: new Date(this.schemeInfoList[0].repayTime).getTime(),
            preRepayAmount: financeAmountAll,
            contractRepaymentArr: '',
            repaymentCondition: '',
            isDate: true
          }
        ]
      } else {
        // 构建预览参数

        const data = {
          preLoanDate: this.formData.preLoanDate,
          financeEndDate: endDate,
          financeAmount: financeAmountAll,
          ...this.schemeInfoList[0].finRepayStyleDTO
        }
        console.log(data, 'data')
        contactApi.previewRepayPlan(data).then((res) => {
          if (res.success) {
            this.finRepayPlan = res.data
          }
        })
      }
    },
    // 修改股东会
    changeValidTimeUnit (data) {
      this.$set(this.shareholderResultValidInfo, 'validTimeUnit', data)
    },
    // 修改股东会决议
    changeResolutionFrom (data) {
      console.log(data, 'newDta')
      this.resultValidTimeType = data.resultValidTimeType
      this.shareholderResultValidInfo = data.shareholderResultValidInfo
      this.shareholderResultValidInfo.validRemark =
        data.validRemark
      this.resultValidTimeFileType = data.resultValidTimeFileType
      // 切换文件类型 续用重新查询
    },
    // 清除股东会决议有效性
    clearShareholderMeeting () {
      this.resultValidTimeType = '0'
      this.singleTransactionType = ''
      this.shareholderResultValidInfo = {
        resultValidTimeStart: '',
        resultValidTimeEnd: '',
        guaranteeQuota: '',
        busiApproveTime: '',
        validTimeNum: '',
        validTimeUnit: '',
        keyId: '',
        surplusGuaranteeQuota: '',
        validRemark: ''
      }
    },
    // 修改股东会决议有效性
    changeShareholderMeetingResolution (data) {
      console.log(data, 'data')
    },
    changeSingleTransactionType (data) {
      this.singleTransactionType = data
      console.error(data)
      this.shareholderResultValidInfo = {
        resultValidTimeStart: '',
        resultValidTimeEnd: '',
        guaranteeQuota: '',
        busiApproveTime: '',
        validTimeNum: '',
        validTimeUnit: '',
        keyId: '',
        surplusGuaranteeQuota: '',
        validRemark: ''
      }
    },
    // 股东会决议有效性
    getValidInfo (data) {
      this.shareholderResultValidInfos = {
        resultValidTimeStart: '',
        resultValidTimeEnd: '',
        guaranteeQuota: '',
        busiApproveTime: '',
        validTimeNum: '',
        validTimeUnit: '',
        keyId: '',
        surplusGuaranteeQuota: '',
        validRemark: ''
      }
      if (data) {
        data.time = [data.resultValidTimeStart, data.resultValidTimeEnd]
        for (const key in this.shareholderResultValidInfos) {
          this.$set(this.shareholderResultValidInfos, key, data[key])
        }
      }

      console.log(this.shareholderResultValidInfos, data, '123')
    },
    // 修改监管账号
    addSelectAccount (data) {
      for (const key in data) {
        this.$set(this.accountData, key, data[key])
      }
    },
    // 修改保理账号
    changeFinRepayBankAccountInfoDTO (data, index) {
      for (const key in data) {
        this.$set(this.finRepayBankAccountInfoDTOs[index], key, data[key])
      }
    },
    // 修改个人担保签约主体
    changePersonalData (data) {
      this.personalData = data
    },
    // 修改企业担保签约主体
    changeEnterpriseData (data) {
      this.enterpriseData = data
    },
    // 查询对应企业和员工信息
    listbylabel () {
      contactApi.listbylabel({ enterpriseLabel: 5 }).then((res) => {
        if (res.success) {
          this.mainPartData = res.data
        }
      })
      // 查询服务费率收款主体
      contactApi.listbylabel({ enterpriseLabel: 6 }).then((res) => {
        if (res.success) {
          this.payeeData = res.data
        }
      })
    },
    changePreLoanDate () {
      this.$set(this.formData, 'preLoanDate', '')
    },
    // 浏览器滚动事件设置选择
    handleScrollx () {
      if (!document.getElementById('contentRZJY')) {
        return false
      }
      const jump = document.querySelectorAll('.el-collapse-item')
      if (this.scrollTop <= document.getElementById('contentRZJY').scrollTop) {
        // 从上往下滚动
        this.scrollTop = document.getElementById('contentRZJY').scrollTop

        if (this.scrollTop === 0) {
          this.active = 0
        }
        for (let i = 0; i < jump.length; i++) {
          jump[i].index = i
          if (
            this.scrollTop >= jump[i].offsetTop - 10 &&
            this.scrollTop <= jump[i].offsetTop + 180
          ) {
            for (let j = 0; j < jump.length; j++) {
              this.active = i + 1
            }
          }
        }
      } else if (
        this.scrollTop > document.getElementById('contentRZJY').scrollTop
      ) {
        this.scrollTop = document.getElementById('contentRZJY').scrollTop
        if (this.scrollTop === 0) {
          this.active = 0
        }
        for (let i = 0; i < jump.length; i++) {
          jump[i].index = i
          if (jump[i].offsetTop - this.scrollTop <= 0) {
            this.active = i + 1
          }
        }
      }

      clearTimeout(this.scrollxTime)
      this.scrollxTime = setTimeout(() => {
        // 节流
        // 获取需要滚动的距离
        // for (let i = 0; i < jump.length; i++) {
        //   if (scrollTop >= jump[i].offsetTop - 202) {
        //     this.active = i
        //   }
        //   if (i === 3) {
        //     if (scrollTop > jump[2].offsetTop - 10 && scrollTop < jump[2].offsetTop + 202) {
        //       this.active = 3
        //     }
        //   }
        //   if (i === 4) {
        //     if (scrollTop > jump[2].offsetTop + 202 && scrollTop < jump[2].offsetTop + 402) {
        //       this.active = 4
        //     }
        //   }
        // }
      }, 50)
    },
    backTop () {
      this.touchactive(0)
    },
    // 点击楼层，滚动到相应位置
    touchactive (id) {
      this.active = JSON.parse(JSON.stringify(id))
      const jump = document.querySelectorAll('.el-collapse-item')
      // 获取需要滚动的距离
      let total = ''
      // if (id === 4) {
      //   total = jump[id].offsetTop - 652
      // } else {

      // }
      total = jump[id].offsetTop - 195
      // Chrome
      document.getElementById('contentRZJY').scrollTop = total
    },
    // 获取融资决议详情
    async detail () {
      this.pass = true
      this.api.detail({ businessId: this.businessId }).then(async (res) => {
        if (res.success) {
          this.detailData = JSON.parse(JSON.stringify(res.data))
          if (!res.data.financeType) {
            this.detailData.financeType = 'ONCE_MODEL'
          }
          this.commitmentLetter = res.data.commitmentLetter
          // 签约主体回显
          if (this.detailData.finCompanyGuaranteeInfos[0]) {
            // 企业
            this.enterpriseSelect = this.detailData.finCompanyGuaranteeInfos[0]
              .loanAssistanceOrgId
              ? this.detailData.finCompanyGuaranteeInfos[0].loanAssistanceOrgId
              : ''
          }
          if (this.detailData.finPersonnalGuaranteeInfos[0]) {
            // 个人
            this.personalSelect = this.detailData.finPersonnalGuaranteeInfos[0]
              .loanAssistanceOrgId
              ? this.detailData.finPersonnalGuaranteeInfos[0]
                .loanAssistanceOrgId
              : ''
          }
          if (res.data.fileListInfo) {
            this.$set(
              this.fileFormData,
              'fileName',
              res.data.fileListInfo.fileName
            )
            this.generatefileId =
              res.data.fileListInfo.fileId &&
              res.data.fileListInfo.fileId !== '0'
                ? res.data.fileListInfo.fileId
                : ''
          }
          this.keyId = res.data.keyId
          this.finBusuinessInfo = res.data.finBusuinessInfo
          // 融资方案信息保存为0处理
          if (this.detailData.finPlanInfos) {
            this.detailData.finPlanInfos.forEach((item) => {
              item.mainBorrowerId =
                parseFloat(item.mainBorrowerId) === 0
                  ? ''
                  : item.mainBorrowerId
              item.coBorrowerId =
                parseFloat(item.coBorrowerId) === 0 ? '' : item.coBorrowerId
              item.capitalSideId =
                parseFloat(item.capitalSideId) === 0 ? '' : item.capitalSideId
              item.financeRate =
                parseFloat(item.financeRate) === 0 ? '' : item.financeRate
              item.financeTerm =
                parseFloat(item.financeTerm) === 0 ? '' : item.financeTerm
              // item.financeAmount = parseFloat(item.financeAmount) === 0 ? '' : item.financeAmount
            })
          }
          // 保理服务信息保存为0处理
          if (this.detailData.finFactoringServiceInfo) {
            this.factoringRelatedForm.forEach((item) => {
              if (
                parseInt(this.detailData.finFactoringServiceInfo[item.prop]) ===
                0
              ) {
                this.detailData.finFactoringServiceInfo[item.prop] = ''
              }
            })
          }
          // 其他备注 备注回显
          this.otherNoteRemark = res.data.otherNoteRemark
          // 签署承诺函回显
          this.signatory = res.data.signatory
          this.factoring = res.data.factoring
          this.finContractInfoNames = res.data.finContractInfoNames || res.data.finBusuinessInfo.gysCompanyName
          // 评审会问题及落实回显
          this.juryQuestionAnswer = res.data.juryQuestionAnswer || '' // 评审会问题及落实
          // 贷后管理措施回显
          this.afterLoanMethod = res.data.afterLoanMethod || '' // 贷后管理措施
          // 抵质押物回显
          const getPawnListParams = {
            businessId: this.businessId
          }
          resolutionAPi.getPawnList(getPawnListParams).then((res) => {
            if (res.data) {
              this.finPawnInfos = res.data || [] // 抵质押物
            }
          })
          // 融资决议概述回显
          this.finResultSummary = res.data.finResultSummary
          // 放款前置条件
          this.lendingBeforeCondition = res.data.lendingBeforeCondition
          // 放款后落实安排
          this.lendingAfterArrange = res.data.lendingAfterArrange
          // 已控制关联资金路径的其他账户
          this.finResultInfoAssociatedAccountVOList =
            res.data.finResultInfoAssociatedAccountVOList
          // 新股东会决议有效性回显
          this.resultValidTimeFileType = res.data.resultValidTimeFileType ? res.data.resultValidTimeFileType : '0'
          // 股东会决议有效性回显
          this.resultValidTimeType = res.data.resultValidTimeType
          // 非单笔 查询 股东会决议有效性

          const params = {
            businessId: this.businessId,
            resultValidTimeFileType: this.resultValidTimeType
          }
          if (this.singleTransactionType === '1' || this.resultValidTimeType === '0') {
            resolutionAPi
              .getShareholderResultValidInfo(params)
              .then((res) => {
                if (res.data) {
                  this.shareholderResultValidInfo = res.data
                  this.$set(this.shareholderResultValidInfo, 'guaranteeQuota', this.shareholderResultValidInfo.guaranteeQuota ? this.shareholderResultValidInfo.guaranteeQuota / 100000000 : '')
                  // this.$set(this.shareholderResultValidInfo, 'time', [res.data.resultValidTimeStart, res.data.resultValidTimeEnd])
                }
              })
          } else if (this.singleTransactionType === '2') {
            resolutionAPi.getResultValid(params).then((res) => {
              if (res.data && res.data.length > 0) {
                res.data.forEach((item) => {
                  if (item.selected) {
                    this.shareholderResultValidInfo = item
                    this.shareholderResultValidInfo.guaranteeQuota = this.shareholderResultValidInfo.guaranteeQuota / 100000000 || ''
                    // this.$set(this.shareholderResultValidInfo, 'time', [item.resultValidTimeStart, item.resultValidTimeEnd])
                  }
                })
              }
              this.$set(this.shareholderResultValidInfo, 'validRemark', this.detailData.validRemark)
            })
          }

          // 还款计划回显
          this.finRepayPlan = res.data.repayPlans
          if (res.data.repayPlans.length > 0) {
            this.repaymentPlanContract =
              res.data.repayPlans[0].finRepayContracts || []
          }

          if (this.finRepayPlan) {
            this.finRepayPlan.forEach((item) => {
              if (item.repayContractId) {
                item.contractRepayment = item.repayContractId.split(',')
              }
            })
          }
          if (this.repaymentPlanContract) {
            this.repaymentPlanContract.forEach((item) => {
              item.contractName = item.finContractName
              item.keyId = item.repayContractId
            })
          }
          if (this.finBusuinessInfo) {
            const { data } = await contactApi.contractList({
              businessId: this.businessId,
              mainBorrowerId: this.finBusuinessInfo.gysId
            })
            this.pledgorList = []
            this.pledgorList.push({
              name: this.finBusuinessInfo.gysCompanyName,
              creditCode: this.finBusuinessInfo.creditCode,
              gysId: this.finBusuinessInfo.gysId,
              pledgorData: [],
              contractData: data
            })
          }
          this.costPrice = res.data.finPlanInfos[0].costPrice
          this.schemeInfoList = res.data.finPlanInfos.length > 0 ? res.data.finPlanInfos : [{ serviceRateStr: '', serviceRateState: false }] // 融资方案
          this.schemeInfoList.forEach((ele, index) => {
            const capitalNameAndProductName = `${ele.capitalSideName}-${ele.capitalSideProductName}`
            this.$set(
              ele,
              'capitalNameAndProductName',
              capitalNameAndProductName
            )
            this.$set(
              ele,
              'finaaceProductName',
              res.data.finBusuinessInfo.finaaceProductName
            )
            // 更具产品，默认显示资金方
            if (ele.finaaceProductName === '稳享付' && !ele.loanAssistanceOrg) {
              this.$set(ele, 'loanAssistanceOrg', '深圳市稳诚一方数字科技服务有限公司')
              this.$set(ele, 'loanAssistanceOrgId', '2110122124237546237')
            } else if (ele.finaaceProductName === '稳时付' && !ele.loanAssistanceOrg) {
              this.$set(ele, 'loanAssistanceOrg', '北海弘扬供应链管理有限公司')
              this.$set(ele, 'loanAssistanceOrgId', '1642476711000000001')
            }
            this.finaaceProductName = res.data.finBusuinessInfo.finaaceProductName
            this.$set(ele, 'creditCode', '')
            this.$set(ele, 'idCard', '')
            this.$set(ele, 'serviceRateStr', '')
            this.$set(ele, 'serviceRateState', false)
            this.$set(
              ele,
              'repayTypeCode',
              ele.finRepayStyleDTO
                ? ele.finRepayStyleDTO.repayStatus
                  ? ele.finRepayStyleDTO.repayStatus
                  : '0'
                : '0'
            )
            if (!ele.finRepayStyleDTO) {
              this.$set(ele, 'finRepayStyleDTO', {
                advanceRepayTerm: '', // 提前还款期限
                chargeParty: '', // 收费主体
                finPlanId: '', // 融资方案ID
                repayAmount: '', // 还款金额
                repayRate: '', // 还款利率
                repayStatus: '0', // 还款方式
                repayTime: '', // 还款时间(多个时间用逗号隔开)
                repayType: '' // 还款方式（ 1按照每月金额 2按照固定利率）
              })
            }
            const [obj] = this.$refs.newFinancingData.providertData.filter(
              (item) => item.keyId === ele.capitalSideId
            )
            this.$set(ele, 'surplusQuota', obj ? obj.surplusQuota : 0)
            const arr = []
            let state = true
            if (ele.finServiceCollectInfo) {
              ele.finServiceCollectInfo.forEach((eles) => {
                if (eles.chargeParty) {
                  arr.push(eles.chargeRate + '%(' + eles.chargeParty + ')')
                } else {
                  state = false
                }
              })
              if (ele.finServiceCollectInfo.length === 0) {
                state = false
              }
            } else {
              state = false
            }
            if (state) {
              ele.serviceRateStr = arr.join(',').replace(/,/g, ';')
              ele.serviceRateState = true
            } else {
              ele.serviceRateStr = ele.serviceRate ? ele.serviceRate : ''
              ele.serviceRateState = false
            }
          })
          if (this.detailData.financeType) {
            const data = await this.$refs.financingData.companys(
              this.detailData.financeType,
              'detail'
            )
            console.log(data)
          } else {
            this.setPledgorData('type')
          }
          this.auditInfo = res.data.finReviewStatus
          this.dataList = res.data.finReviewOpinionInfo
          if (res.data.finRepayBankAccountInfoVOs && res.data.finRepayBankAccountInfoVOs.length > 0) {
            this.finRepayBankAccountInfoDTOs = res.data.finRepayBankAccountInfoVOs
          }
          // this.finRepayBankAccountInfoDTOs = res.data.finRepayBankAccountInfoVOs.length > 0 ? res.data.finRepayBankAccountInfoVOs : [{}]

          // this.$nextTick(() => {
          //   this.$refs.accountAndService.clearFromValidate()
          // })
          this.accountData = res.data.finSuperviseBankAccountInfoDTO || {
            remark: ''
          }
          this.enterpriseData = res.data.finCompanyGuaranteeInfos.length === 0 ? [res.data.jhDefaultGuaranteeInfo] : res.data.finCompanyGuaranteeInfos // 企业担保
          this.secondDefaultEnterpriseData = res.data.jhDefaultGuaranteeInfo || [] // 二级默认企业担保
          this.alienatorData = res.data.finContractInfosTransfer || [] // 转让信息

          this.alienatorData.forEach((ele) => {
            // ele.pledgeValue = ele.pledgeValue ? ele.pledgeValue : ''
            // ele.relLoanAmount = ele.relLoanAmount ? ele.relLoanAmount : ''
            this.pledgorList[0].contractData.forEach((eles) => {
              if (eles.keyId === ele.finContractId) {
                ele.keyIds = eles.keyId
              }
            })
          })

          this.contractInfoData = res.data.finContractInfosSupplement || [] // 补充合同信息
          if (res.data.finFactoringServiceInfo) {
            res.data.finFactoringServiceInfo.factoryAmount =
              res.data.finFactoringServiceInfo.factoryAmount.toString()
            if (
              res.data.finFactoringServiceInfo.factoryAmount &&
              res.data.finFactoringServiceInfo.factoryAmount.indexOf('.') < 0
            ) {
              res.data.finFactoringServiceInfo.factoryAmount =
                res.data.finFactoringServiceInfo.factoryAmount + '.00'
            }
          }

          this.serviceData = { ...res.data.finFactoringServiceInfo } || {} // 保理服务信息

          this.personalData = res.data.finPersonnalGuaranteeInfos || [] // 个人担保
          console.log(this.personalData, 'personalData')
          this.formData = {
            signTime: res.data.signTime,
            remark: res.data.remark,
            suppliersSealType: res.data.finBusuinessInfo
              ? res.data.finBusuinessInfo.suppliersSealType ||
                res.data.finBusuinessInfo.suppliersSealType === 0
                ? res.data.finBusuinessInfo.suppliersSealType.toString()
                : ''
              : '',
            financeType: res.data.financeType
              ? res.data.financeType
              : 'ONCE_MODEL',
            signType: res.data.signType,
            otherSignRemark: res.data.otherSignRemark,
            meettingDate: res.data.meettingDate
              ? formatDate(res.data.meettingDate, 'YY-MM-DD') !== '--'
                ? formatDate(res.data.meettingDate, 'YY-MM-DD').replace(
                  /\//g,
                  '-'
                )
                : ''
              : '',
            preLoanDate: res.data.preLoanDate
              ? formatDate(res.data.preLoanDate, 'YY-MM-DD') !== '--'
                ? formatDate(res.data.preLoanDate, 'YY-MM-DD').replace(
                  /\//g,
                  '-'
                )
                : ''
              : ''
          }

          this.meetingSummaryDetailVOList =
            res.data.meetingSummaryDetailVOList || [] // 评审会问题及落实回显
          this.$nextTick(() => {
            this.$refs.otherData.$refs.otherFrom.clearValidate()
            this.$refs.financingData.$refs.schemeFormData.forEach((ele) => {
              ele.clearValidate()
            })
            this.$refs.newFinancingData.$refs.repeatSchemeFormData.clearValidate()
            this.$refs.accountAndService.$refs.factoringForm.clearValidate()
            this.$refs.accountAndService.$refs.superviseForm.clearValidate()
            // if (this.$refs.accountAndService.$refs.settlementAccountForm) {
            //   this.$refs.accountAndService.$refs.settlementAccountForm.clearValidate()
            // }
            this.$refs.accountAndService.$refs.settlementAccountForm.forEach((ele) => {
              ele.clearValidate()
            })
            this.$refs.otherFile.clearValidate()
            this.$refs.shareholderData.$refs.shareholderMeeting.clearValidate()
          })
          setTimeout(() => {
            this.pass = false
          }, 2000)
        }
      })
    },
    // 切换融资模式
    async  changeFinanceType (type) {
      this.$nextTick(() => {
        console.log(this.$refs.financingData)
        this.$refs.financingData.$refs.schemeFormData[0].clearValidate()
        this.$refs.newFinancingData.$refs.repeatSchemeFormData.clearValidate()
        this.$refs.accountAndService.$refs.factoringForm.clearValidate()
        this.$refs.accountAndService.$refs.superviseForm.clearValidate()
      })
      // 切换资金方，默认设置收费主体
      if (this.schemeInfoList[0].finaaceProductName === '稳享付') {
        this.$set(this.schemeInfoList[0], 'loanAssistanceOrg', '深圳市稳诚一方数字科技服务有限公司')
        this.$set(this.schemeInfoList[0], 'loanAssistanceOrgId', '2110122124237546237')
      } else if (this.schemeInfoList[0].finaaceProductName === '稳时付') {
        this.$set(this.schemeInfoList[0], 'loanAssistanceOrg', '北海弘扬供应链管理有限公司')
        this.$set(this.schemeInfoList[0], 'loanAssistanceOrgId', '1642476711000000001')
      }
      const before = this.schemeInfoList[0]
      const baseData = {
        serviceRateStr: '',
        serviceRateState: false,
        repayTypeCode: '0',
        finRepayStyleDTO: {
          advanceRepayTerm: '', // 提前还款期限
          chargeParty: '', // 收费主体
          finPlanId: '', // 融资方案ID
          repayAmount: '', // 还款金额
          repayRate: '', // 还款利率
          repayStatus: '0', // 还款方式
          repayTime: '', // 还款时间(多个时间用逗号隔开)
          repayType: '' // 还款方式（ 1按照每月金额 2按照固定利率）
        }
      }
      // 原始数据赋值
      if (before) {
        baseData.capitalNameAndProductName = before.capitalNameAndProductName
        baseData.capitalSideName = before.capitalSideName
        baseData.capitalSideProductId = before.capitalSideProductId
        baseData.capitalSideProductName = before.capitalSideProductName
        baseData.capitalSideId = before.capitalSideId
        baseData.financeRate = before.financeRate
        baseData.loanAssistanceOrg = before.loanAssistanceOrg
        baseData.loanAssistanceOrgId = before.loanAssistanceOrgId
      }
      this.schemeInfoList = [baseData]
      this.$set(
        this.schemeInfoList[0],
        'finaaceProductName',
        this.finaaceProductName
      )
      this.$set(
        this.schemeInfoList[0],
        'financeRate',
        ''
      )
      this.totalAmount = 0
      this.$set(this.schemeInfoList[0], 'costPrice', this.costPrice)
      this.pledgorList = this.pledgorList[0] ? [this.pledgorList[0]] : []
      this.enterpriseData = []

      // this.finRepayBankAccountInfoDTOs = [
      //   {
      //     account: '',
      //     accountNo: '',
      //     accountType: 0,
      //     bankId: '',
      //     bankName: '',
      //     enterpriseId: '',
      //     keyId: '',
      //     memo: ''
      //   }
      // ]
      this.finRepayBankAccountInfoDTOs = [{}]
      this.enterpriseData = [this.secondDefaultEnterpriseData]

      this.accountData = { account: '' }
      this.contractInfoData = []
      this.alienatorData = []
      // this.serviceData = {}
      this.personalData = []
      const obj = {
        financeType: type,
        signType: '',
        suppliersSealType: '',
        meettingDate: '',
        preLoanDate: ''
      }
      this.$set(this.formData, {}, obj)
      console.log(this.formData, 'change')
      if (type) {
        this.$refs.financingData.companys(type)
        let status = true
        this.schemeInfoList.forEach((ele) => {
          if (
            this.finBusuinessInfo.gysCompanyName &&
            ele.mainBorrower === this.finBusuinessInfo.gysCompanyName
          ) {
            status = false
          }
          // 更具产品，默认显示资金方
          // if (ele.finaaceProductName === '稳享付' && !ele.loanAssistanceOrg) {
          //   this.$set(ele, 'loanAssistanceOrg', '深圳市稳诚一方数字科技服务有限公司')
          //   this.$set(ele, 'loanAssistanceOrgId', '2110122124237546237')
          // } else if (ele.finaaceProductName === '稳时付' && !ele.loanAssistanceOrg) {
          //   this.$set(ele, 'loanAssistanceOrg', '北海弘扬供应链管理有限公司')
          //   this.$set(ele, 'loanAssistanceOrgId', '1642476711000000001')
          // }
        })
        if (!status && type === 'SECOND_MODEL') {
          return this.warning('主借人不能相同')
        }
        if (type === 'ONCE_MODEL') {
          if (this.finBusuinessInfo) {
            const { data } = await contactApi.contractList({
              businessId: this.businessId,
              mainBorrowerId: this.finBusuinessInfo.gysId
            })
            this.pledgorList = []
            this.pledgorList.push({
              name: this.finBusuinessInfo.gysCompanyName,
              creditCode: this.finBusuinessInfo.creditCode,
              gysId: this.finBusuinessInfo.gysId,
              contractData: data
            })
          }
          this.finRepayBankAccountInfoDTOs = [this.finRepayBankAccountInfoDTOs[0]]
        }
      } else {
        this.personsDatas = []
        await this.pledgorList.forEach((ele) => {
          this.$set(ele, 'pledgorData', [])
        })
        this.detailData.finContractInfosPlain = []
      }

      this.setPledgorData()
    },
    // 设置质押信息数据
    setPledgorData (type) {
      this.pledgorList.forEach((ele) => {
        const arr = []
        if (this.detailData.finContractInfosPlain) {
          this.detailData.finContractInfosPlain.forEach((eles) => {
            if (ele.gysId === eles.gysId) {
              arr.push(eles)
            }
          })
        }
        if (type === 'detail') {
          this.$set(ele, 'pledgorData', arr)
        } else {
          if (!ele.pledgorData) {
            this.$set(ele, 'pledgorData', arr)
          }
        }
        ele.pledgorData.forEach((item) => {
          // 质押信息
          // item.pledgeValue = item.pledgeValue ? item.pledgeValue : ''
          // item.relLoanAmount = item.relLoanAmount ? item.relLoanAmount : ''
          if (type === 'detail') {
            ele.contractData.forEach((eles) => {
              if (eles.keyId === item.finContractId) {
                item.keyIds = eles.keyId
              }
            })
          }
        })
      })
    },
    // 计算结束时间
    getRepayTime (data) {
      if (!data.financeTerm || !this.formData.preLoanDate) return
      const advanceRepayTerm = data.financeTerm
      const str = this.formData.preLoanDate.replace(/-/g, '/')
      const yearItem =
        parseInt(advanceRepayTerm) >= 12
          ? parseInt(parseInt(advanceRepayTerm) / 12)
          : 0
      const monthItem =
        parseInt(advanceRepayTerm) < 12
          ? parseInt(advanceRepayTerm)
          : parseInt(advanceRepayTerm) % 12
      var date = new Date(str)
      var year = date.getFullYear() + yearItem
      var month = date.getMonth() + monthItem + 1
      if (month > 12) {
        year++
        month -= 12
      }
      if (month < 10) {
        month = '0' + month
      }
      var date2 = new Date(year, month, 0)
      var day1 = date.getDate()
      var day2 = date2.getDate()
      if (day1 > day2) {
        day1 = day2
      }
      if (day1 < 10) {
        day1 = '0' + day1
      }
      return year + '-' + month + '-' + day1
    },
    // 融资决议制作
    async saveData (type) {
      const passStatus = await this.api
        .checkTimeOutOrNot({ businessId: this.$route.query.keyId })
        .then((res) => {
          if (!res.data.status) {
            this.warning(res.data.errorMsg)
            this.$router.back()
            return false
          } else {
            return true
          }
        })
      if (!passStatus) {
        return
      }
      let state = true
      const pledgorData = []
      if (type !== 'save') {
        if (!this.formData.financeType) {
          return this.warning('请选择融资模式')
        }
        if (!this.formData.preLoanDate) {
          return this.warning('请选择预计放款时间')
        }
        if (!this.formData.meettingDate) {
          const message =
            this.schemeInfoList[0].finaaceProductName === '稳时付'
              ? '申请日期'
              : '上会日期'
          return this.warning('请选择' + message)
        }
        // 融资决议概述校验
        if (!this.finResultSummary) {
          this.errMsg(0, false)
          if (this.showMain === 0) {
            console.log(this.$refs.scrollFool, ' this.$refs.scrollFool')
            this.$refs.scrollFool.touchactive(0)
          }
          this.warning('请完善融资决议概述信息')
          return false
        }
        // 融资信息校验
        const schemeForm = this.$refs.financingData.$refs.schemeFormData
        for (let i = 0; i < schemeForm.length; i++) {
          schemeForm[i].validate((valid) => {
            if (!valid) {
              state = false
            }
          })
        }
        if (!state) {
          state = true
          this.errMsg(0, false)
          if (this.showMain === 0) {
            this.$refs.scrollFool.touchactive(1)
          }

          this.warning('请完善融资方案信息')
          return false
        }
        // 融资信息 主借人验证
        let status = true
        this.schemeInfoList.forEach((ele) => {
          if (
            this.finBusuinessInfo.gysCompanyName &&
            ele.mainBorrower === this.finBusuinessInfo.gysCompanyName
          ) {
            status = false
          }
        })
        if (!status && this.formData.financeType === 'SECOND_MODEL') {
          this.errMsg(0, false)
          if (this.showMain === 0) {
            this.$refs.scrollFool.touchactive(1)
          }
          return this.warning('主借人不能相同')
        }
        this.$refs.newFinancingData.$refs.repeatSchemeFormData.validate(
          (valid) => {
            if (!valid) {
              state = false
            }
          }
        )
        if (!state) {
          this.errMsg(0, false)
          if (this.showMain === 0) {
            this.$refs.scrollFool.touchactive(1)
          }
          return this.warning('请完善融资方案信息')
        }
        // 分批还款信息校验
        this.pass = true
        let repayAmountPass = false
        this.schemeInfoList.forEach((ele) => {
          ele.repayTime = this.getRepayTime(ele)
          if (ele.repayTypeCode === '0' || ele.repayTypeCode === '2') {
            // 一次性还本付息 || 一次性还本按月付息
            ele.finRepayStyleDTO.repayTime = ele.repayTime
            ele.chargeParty = ele.mainBorrower
          } else if (ele.repayTypeCode === '1') {
            // 分批还款
            if (
              !ele.finRepayStyleDTO.repayAmount &&
              !ele.finRepayStyleDTO.repayRate
            ) {
              repayAmountPass = true
            }
            ele.finRepayStyleDTO.chargeParty = ele.mainBorrower
            ele.repayStatus = ele.finRepayStyleDTO.repayStatus
            ele.repayAmount = ele.finRepayStyleDTO.repayAmount
            ele.chargeParty = ele.mainBorrower
          }
          if (!ele.serviceRateState) {
            ele.finServiceCollectInfo = []
            ele.serviceRate = parseFloat(ele.serviceRateStr)
          } else {
            ele.serviceRate = ''
          }
        })
        if (type !== 'save') {
          if (repayAmountPass) {
            this.errMsg(0, false)
            if (this.showMain === 0) {
              this.$refs.scrollFool.touchactive(8)
            }
            this.warning('请完善分批还款信息')
            return false
          }
        }
        // 质押信息校验
        let pledgorStatus = true

        let errorMsg = ''
        this.pledgorList.forEach((ele) => {
          if (ele.pledgorData.length === 0) {
            pledgorStatus = false
            errorMsg = '请完善质押信息'
          }
          ele.pledgorData.forEach((ele) => {
            if (
              !ele.finContractId ||
              !ele.finContractName ||
              (!ele.pledgeValue && ele.pledgeValue !== 0) ||
              (!ele.relLoanAmount && ele.relLoanAmount !== 0)
            ) {
              pledgorStatus = false
              errorMsg = '请完善质押信息'
            }
            // 取消校验 0916反馈
            // if (ele.finContractAmount) {
            //   if (ele.finContractAmount - ele.pledgeValue <= 0) {
            //     errorMsg = '质押价值必须小于质押合同对价'
            //     pledgorStatus = false
            //   }
            // }
            // if (ele.pledgeValue - ele.relLoanAmount < 0) {
            //   errorMsg = '质押合同对应融资额必须小于等于质押价值'
            //   pledgorStatus = false
            // }
          })
        })
        if (!pledgorStatus) {
          this.errMsg(0, false)
          if (this.showMain === 0) {
            this.$refs.scrollFool.touchactive(3)
          }
          return this.warning(errorMsg)
        }
        // 个人企业担保校验  稳时付个人担保未非必填 稳享付必填
        if (this.finaaceProductName === '稳享付') {
          if (this.personalData.length === 0) {
            this.errMsg(0, false)
            if (this.showMain === 0) {
              this.$refs.scrollFool.touchactive(4)
            }
            return this.warning('请添加个人担保')
          }
        }

        if (this.enterpriseData.length === 0) {
          this.errMsg(0, false)
          if (this.showMain === 0) {
            this.$refs.scrollFool.touchactive(4)
          }
          return this.warning('请添加企业担保')
        }
        // 股东会决议有效性验证

        this.$refs.shareholderData.$refs.shareholderMeeting.validate(
          (valid) => {
            if (!valid) {
              state = false
            }
          }
        )
        if (!state) {
          state = true
          this.errMsg(0, false)
          if (this.showMain === 0) {
            this.$refs.scrollFool.touchactive(5)
          }
          this.warning('请完善股决/董决有效性信息')
          return false
        }
        if (this.resultValidTimeType !== '0') {
          // 非单笔验证
          if (this.resultValidTimeType === '1') {
            // 重出
            this.$refs.shareholderData.$refs.shareholderMeeting.$refs.ShareholderMeetingType.$refs.ShareholderMeetingType.validate(
              (valid) => {
                if (!valid) {
                  state = false
                }
              }
            )
            if (this.shareholderResultValidInfo) {
              if (!this.shareholderResultValidInfo.resultValidTimeStart) {
                this.errMsg(0, false)

                if (this.showMain === 0) {
                  this.$refs.scrollFool.touchactive(5)
                }
                this.warning('请完善股决/董决有效性信息')
                return false
              }
            } else {
              this.errMsg(0, false)

              if (this.showMain === 0) {
                this.$refs.scrollFool.touchactive(5)
              }
              this.warning('请完善股决/董决有效性信息')
              return false
            }
          } else {
            if (this.shareholderResultValidInfos) {
              if (!this.shareholderResultValidInfos.resultValidTimeStart) {
                this.errMsg(0, false)

                if (this.showMain === 0) {
                  this.$refs.scrollFool.touchactive(5)
                }
                this.warning('请完善股决/董决有效性信息')
                return false
              }
            } else {
              this.errMsg(0, false)

              if (this.showMain === 0) {
                this.$refs.scrollFool.touchactive(5)
              }
              this.warning('请完善股决/董决有效性信息')
              return false
            }
          }

          if (!state) {
            this.errMsg(0, false)

            if (this.showMain === 0) {
              this.$refs.scrollFool.touchactive(5)
            }
            this.warning('请完善信息')
            return
          }
          // 融资方案  this.schemeInfoList[0].financeAmount
          // 股东会决议有效性 this.shareholderResultValidInfo.guaranteeQuota
          // if (
          //   this.resultValidTimeType === '1' &&
          //   this.shareholderResultValidInfo.guaranteeQuota * 100000000 -
          //     this.schemeInfoList[0].financeAmount <
          //   0
          // ) {
          //   this.errMsg(0, false)
          //   if (this.showMain === 0) {
          //     this.$refs.scrollFool.touchactive(5)
          //   }
          //   this.warning('股东会决议担保额度不能小于融资金额')
          //   return false
          // }
        }
        // 监管账户验证

        this.$refs.accountAndService.$refs.superviseForm.validate((valid) => {
          if (!valid) {
            state = false
          }
        })
        this.$refs.accountAndService.$refs.factoringForm.validate((valid) => {
          if (!valid) {
            state = false
          }
        })
        // // 财富资金验证保理结算账户
        // if (this.$refs.accountAndService.$refs.settlementAccountForm && Array.isArray(this.$refs.accountAndService.$refs.settlementAccountForm)) {
        //   this.$refs.accountAndService.$refs.settlementAccountForm.forEach((item, index) => {
        //     item.validate(
        //       (valid) => {
        //         if (!valid) {
        //           state = false
        //         }
        //       }
        //     )
        //   })
        // }
        if (!state) {
          this.errMsg(0, false)
          if (this.showMain === 0) {
            this.$refs.scrollFool.touchactive(6)
          }
          this.warning('请完善风控措施信息')
          return false
        }
        // 验证转让信息是否完整
        // const newError = ''
        let alienatorStatus = true
        if (this.alienatorData.length === 0) {
          alienatorStatus = false
        }
        this.alienatorData.forEach((ele) => {
          if (
            !ele.finContractId ||
            !ele.finContractName ||
            (!ele.pledgeValue && ele.pledgeValue !== 0) ||
            (!ele.relLoanAmount && ele.relLoanAmount !== 0)
          ) {
            alienatorStatus = false
          }
        })
        if (!alienatorStatus) {
          alienatorStatus = true
          this.errMsg(0, false)
          if (this.showMain === 0) {
            this.$refs.scrollFool.touchactive(6)
          }
          return this.warning('请完善转让信息')
        }

        // this.alienatorData.forEach((item) => {
        // if (item.pledgeValue - item.relLoanAmount < 0) {
        //   alienatorStatus = false
        //   newError = '转让信息对应融资额不能大于转让价值'
        // }
        // if (item.finContractAmount) {
        //   if (item.finContractAmount - item.pledgeValue <= 0) {
        //     alienatorStatus = false
        //     newError = '转让信息转让价值不能大于等于合同对价'
        //   }
        // }

        // if (parseInt(item.pledgeValue) < parseInt(item.relLoanAmount)) {
        //   alienatorStatus = false
        //   newError = '对应融资额不能大于转让价值'
        // }
        // })
        // if (!alienatorStatus) {
        //   this.errMsg(0, false)
        //   if (this.showMain === 0) {
        //     this.$refs.scrollFool.touchactive(6)
        //   }
        //   return this.warning(newError)
        // }
        // let pledgorState = true
        // this.pledgorList.forEach((ele) => {
        //   ele.pledgorData.forEach((eles) => {
        //     if (parseFloat(eles.relLoanAmount) > parseFloat(eles.pledgeValue)) {
        //       pledgorState = false
        //     }
        //   })
        // })
        // if (!pledgorState) {
        //   this.errMsg(0, false)
        //   if (this.showMain === 0) {
        //     this.$refs.scrollFool.touchactive(6)
        //   }
        //   return this.warning('对应融资额不能大于质押价值')
        // }
        // 统一提示
        // for (let i = 0; i < this.schemeInfoList.length; i++) {
        //   const ele = this.schemeInfoList[i]
        // if (ele.surplusQuota < parseFloat(ele.financeAmount)) {
        //   return this.warning(
        //     ` 融资金额 ${ele.financeAmount}(元) 大于 资金方  ${ele.capitalSideName} 剩余额度 ${ele.surplusQuota}(元)`
        //   )
        // }
        // for (let j = 0; j < this.pledgorList.length; j++) {
        //   const jele = this.pledgorList[j]
        //   if (ele.mainBorrowerId === jele.gysId) {
        //     let all = 0
        //     jele.pledgorData.forEach((ele) => {
        //       all = all + parseFloat(ele.relLoanAmount)
        //     })
        //     if (parseFloat(ele.financeAmount) !== all && type !== 'save') {
        //       return this.warning(
        //         `${ele.mainBorrower} 融资金额 不等于 质押信息中对应融资额之和`
        //       )
        //     }
        //   }
        // }
        // }

        // let stairRelLoanAmount = 0 // 一级对应融资额之和
        // let secondRelLoanAmount = 0 // 二级对应融资金额之和
        // if (this.pledgorList.length > 1) {
        //   // 二级模式下判断一级供应商对应融资额是否等于二级供应商融资金额之和
        // this.pledgorList.forEach((ele, index) => {
        //   if (index === 0) {
        //     ele.pledgorData.forEach((eles) => {
        //       stairRelLoanAmount =
        //         stairRelLoanAmount + parseFloat(eles.relLoanAmount)
        //     })
        //   } else {
        //     ele.pledgorData.forEach((eles) => {
        //       secondRelLoanAmount =
        //         secondRelLoanAmount + parseFloat(eles.relLoanAmount)
        //     })
        //   }
        // })
        // if (stairRelLoanAmount !== secondRelLoanAmount && type !== 'save') {
        //   return this.warning(
        //     '一级供应商对应融资额之和不等于二级供应商融资金额之和'
        //   )
        // }
        // }
        if (!state) return this.warning('请完善信息')
        // 签署承诺函及委托付款函验证
        if (!this.signatory || !this.finContractInfoNames) {
          this.errMsg(0, false)
          if (this.showMain === 0) {
            this.$refs.scrollFool.touchactive(7)
          }
          this.warning('请完善签署承诺函及委托付款函验证信息')
          return false
        }
        // 还款计划校验

        if (this.finRepayPlan.length === 0) {
          this.errMsg(0, false)
          console.log(this.showMain, 'this.showMain')
          if (this.showMain === 0) {
            this.$refs.scrollFool.touchactive(8)
          }
          this.warning('请完善还款计划信息')
          return false
        } else {
          let finRepayPlanPass = true
          this.finRepayPlan.forEach((item) => {
            if (
              !item.repayContractId ||
              !item.preRepayAmount ||
              !item.preRepayDate ||
              !item.repayCondition
            ) {
              finRepayPlanPass = false
            }
          })
          if (!finRepayPlanPass) {
            this.errMsg(0, false)
            console.log(this.showMain)
            if (this.showMain === 0) {
              this.$refs.scrollFool.touchactive(8)
            }
            this.warning('请完善还款计划信息')
            return false
          }
        }
        // 还款计划金额校验
        // let allPreRepayAmount = 0
        // let allFinanceAmount = 0
        // this.finRepayPlan.forEach((item) => {
        //   allPreRepayAmount = item.preRepayAmount * 1 + allPreRepayAmount
        // })
        // this.schemeInfoList.forEach((item) => {
        //   allFinanceAmount = item.financeAmount * 1 + allFinanceAmount
        // })
        // console.log(allPreRepayAmount, allFinanceAmount, '22222')
        // if (allPreRepayAmount !== allFinanceAmount) {
        //   this.errMsg(0, false)
        //   if (this.showMain === 0) {
        //     this.$refs.scrollFool.touchactive(8)
        //   }
        //   this.warning('还款计划中还款金额必须等于融资金额')
        //   return false
        // }
        this.errMsg(0, true)
        // 评审会问题及落实校验
        let meetingsPass = true
        if (this.meetingSummaryDetailVOList.length > 0) {
          this.meetingSummaryDetailVOList.forEach((item) => {
            if (!item.description || !item.situation) {
              meetingsPass = false
            }
          })
        }
        if (!meetingsPass) {
          this.errMsg(1, false)
          if (this.showMain === 1) {
            this.$refs.scrollFool.touchactive(9)
          }
          this.warning('请完善评审会问题及落实')
          return false
        }
        // 贷后管理措施数据校验
        if (!this.afterLoanMethod) {
          this.errMsg(1, false)
          if (this.showMain === 1) {
            this.$refs.scrollFool.touchactive(10)
          }
          this.warning('请完善贷后管理措施')
          return false
        }
        // 其他备注数据校验
        this.$refs.otherData.$refs.otherFrom.validate((valid) => {
          if (!valid) {
            state = false
          }
        })
        if (!this.lendingBeforeCondition) {
          state = false
        }
        if (!state) {
          this.errMsg(1, false)
          if (this.showMain === 1) {
            this.$refs.scrollFool.touchactive(11)
          }
          this.warning('请完善其他备注信息')
          return false
        }
        // 当签约方式为其他时 输入框必填
        if (this.formData.signType === '2') {
          if (!this.formData.otherSignRemark) {
            this.errMsg(1, false)
            if (this.showMain === 1) {
              this.$refs.scrollFool.touchactive(11)
            }
            this.warning('请完善其他备注中其他签约方式具体内容')
            return false
          }
        }
        let finPawnInfosPass = true
        if (this.finPawnInfos.length > 0) {
          this.finPawnInfos.forEach((item) => {
            if (!item.pawnName && !item.pawnValue) {
              // 同时为空时 不判断
            } else {
              if (!item.pawnName || !item.pawnValue) {
                finPawnInfosPass = false
              }
            }
          })
        }
        if (!finPawnInfosPass) {
          this.errMsg(1, false)
          if (this.showMain === 1) {
            this.$refs.scrollFool.touchactive(11)
          }
          this.warning('请完善质押物信息')
          return
        }
        // 已控制关联资金路径的其他账户校验
        let finResultInfoAssociatedAccountVOListPass = true
        if (this.finResultInfoAssociatedAccountVOList.length > 0) {
          this.finResultInfoAssociatedAccountVOList.forEach((item) => {
            if (
              !item.factoringAccountName ||
              !item.factoringAccountNumber ||
              !item.factoringAccountBank
            ) {
              finResultInfoAssociatedAccountVOListPass = false
            }
          })
        }
        if (!finResultInfoAssociatedAccountVOListPass) {
          this.errMsg(1, false)
          if (this.showMain === 1) {
            this.$refs.scrollFool.touchactive(11)
          }
          this.warning(
            '请完善其他备注中的已控制关联资金路径的其他账户校验信息'
          )
          return false
        }

        if (type !== 'generate') {
          // otherFile
          this.$refs.otherFile.validate((valid) => {
            if (!valid) {
              state = false
            }
          })
          if (!state) {
            this.errMsg(1, false)
            if (this.showMain === 1) {
              this.$refs.scrollFool.touchactive(12)
            }
            this.warning('请先生成文件')
            return false
          }
        }
        this.errMsg(1, true)
        // if (!this.personalData[0].loanAssistanceOrgId) {
        //   return this.warning('请选择个人担保主体')
        // }

        // if (!this.enterpriseData[0].loanAssistanceOrgId) {
        //   return this.warning('请选择企业担保主体')
        // }

        // 抵质押物
      }
      if (this.pledgorList.length > 0) {
        this.pledgorList.forEach((ele) => {
          ele.pledgorData.forEach((eles) => {
            eles.plainPersonName = ele.name
            eles.plainPersonCreditCode = ele.creditCode
            pledgorData.push(eles)
          })
        })
      }

      // this.shareholderResultValidInfo.resultValidTimeStart = this.shareholderResultValidInfo.time[0]
      // this.shareholderResultValidInfo.resultValidTimeEnd = this.shareholderResultValidInfo.time[1]
      this.shareholderResultValidInfo.orgId = this.finBusuinessInfo.gysId
      this.shareholderResultValidInfos.validRemark = this.shareholderResultValidInfo.validRemark
      const newShareholderResultValidInfo = JSON.parse(JSON.stringify(this.shareholderResultValidInfo))
      newShareholderResultValidInfo.guaranteeQuota = newShareholderResultValidInfo.guaranteeQuota * 100000000

      const params = {
        // 融资决议概述
        finResultSummary: this.finResultSummary,
        // 其他备注中 备注
        otherNoteRemark: this.otherNoteRemark,
        // 有效性信息文件类型 0.股东会决议或股东决定(默认) 1.董事会决议 2.全选
        resultValidTimeFileType: this.resultValidTimeFileType,
        // 已控制关联资金路径的其他账户
        finResultInfoAssociatedAccountVOList:
          this.finResultInfoAssociatedAccountVOList,
        // 放款后落实安排
        lendingAfterArrange: this.lendingAfterArrange,
        // 放款前置条件
        lendingBeforeCondition: this.lendingBeforeCondition,
        // 评审会问题及落实
        meetingSummaryDetailVOList: this.meetingSummaryDetailVOList,
        // 签署承诺函
        signatory: this.signatory,
        factoring: this.factoring,
        finContractInfoNames: this.finContractInfoNames,
        resultValidTimeType: this.resultValidTimeType,
        shareholderResultValidInfo: this.resultValidTimeType === '1' ? newShareholderResultValidInfo : (this.resultValidTimeType === '2' ? this.shareholderResultValidInfos : { validRemark: this.shareholderResultValidInfo.validRemark }), // 股东会决议有效性信息DTO 重出和需用才有
        validRemark: this.shareholderResultValidInfo.validRemark,
        finContractInfosCapitalReq: this.finContractInfosCapitalReq, // 资金方要求
        afterLoanMethod: this.afterLoanMethod, // 贷后管理措施
        juryQuestionAnswer: this.juryQuestionAnswer, // 评审会问题及落实
        finPawnInfos: this.finPawnInfos, // 抵质押物
        finRepayPlan: this.finRepayPlan, // 还款计划
        finRepayBankAccountInfoDTOs: this.finRepayBankAccountInfoDTOs,
        commitmentLetter: this.commitmentLetter,
        finCompanyGuaranteeInfos: this.enterpriseData,
        finContractInfosPlain: pledgorData,
        finSuperviseBankAccountInfoDTO: this.accountData,
        finContractInfosSupplement: this.contractInfoData,
        finContractInfosTransfer: this.alienatorData,
        finFactoringServiceInfo: this.serviceData,
        finPersonnalGuaranteeInfos: this.personalData,
        finPlanInfos: this.schemeInfoList,
        businessId: this.businessId,
        instanceId: this.instanceId,
        finReviewStatus: this.detailData.finReviewStatus,
        // finReviewOpinionInfo: this.detailData.finReviewOpinionInfo,
        finBusuinessInfo: this.detailData.finBusuinessInfo,
        fileListInfo: this.detailData.fileListInfo,
        keyId: this.keyId,
        ...this.formData
      }

      if (type === 'save') {
        this.save(params)
      } else if (type === 'generate') {
        // 文件生成
        this.save(params, 'generate')
      } else {
        this.submit(params)
      }
    },
    // 文件生成
    generate (params) {
      this.api.generateFile(params).then((res) => {
        if (res.code === '0') {
          this.success('文件生成成功')
          this.generatefileId = res.data.keyId

          this.pass = false
          this.$set(this.fileFormData, 'fileName', res.data.fileName)
        }
      })
    },
    // 融资决议制作数据保存
    save (params, type) {
      params.fileListInfo.fileName = this.fileFormData.fileName
      params.fileListInfo.fileId = this.generatefileId
      this.api.save(params).then((res) => {
        if (res.success) {
          this.pass = false
          if (type === 'generate') {
            this.generate(params)
          } else {
            this.success('保存成功')
            this.detail()
          }
        }
      })
    },
    // 融资决议制作数据提交
    submit (params) {
      params.fileListInfo.fileName = this.fileFormData.fileName
      params.fileListInfo.fileId = this.generatefileId
      this.api.submit(params).then((res) => {
        if (res.success) {
          this.pass = false
          this.success('提交成功')
          this.close()
        }
      })
    },
    // 文件预览
    previewShow () {
      this.fileType = this.fileFormData.fileName.split('.').pop().toLowerCase()
      if (!this.fileType || !allSupportFileTypes.includes(this.fileType)) {
        this.warning(`不能预览的文件类型：[${this.fileType}]`)
        return
      }

      // this.previsible = true
      this.count++
    },
    // 取消
    close () {
      this.$router.back()
      // this.$router.push('/business/financing-decision-manage')
    }
  }
}
</script>
<style lang="scss" src="../index.scss" scoped></style>
