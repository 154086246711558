<template>
  <!-- 建行云贷 -->
  <!-- 融资方案模块数据 -->
  <div class="financingPlan">
    <div class="borrowerContent">
      <base-button
        label="添加借款人"
        class="addBorrower"
        v-if="
          formData.financeType &&
            formData.financeType != 'ONCE_MODEL' &&
            index === '02'
        "
        @click="addSchemeInfoList"
        icon="el-icon-plus"
      ></base-button>
    </div>
    <!-- 循环处理输入框数据 -->
    <div v-if="index === '02'">
      <div v-for="(item, index) in schemeInfoList" :key="index" class="scheme">
        <div
          class="del"
          v-if="schemeInfoList.length > 1"
          @click="removeSchemeInfo(index)"
        >
          <i class="iconfont iconshanchu1"></i>
        </div>
        <base-form
          :componentList="schemeInfoForm(index)"
          :formAttrs="{
            model: item,
            labelWidth: '140px'
          }"
          :keyIndex="index"
          :showBtns="false"
          class="formData newFormData"
          ref="schemeFormData"
        >
        </base-form>
      </div>
    </div>

    <base-form
      :componentList="repeatSchemeInfo(0)"
      :formAttrs="{
        model: schemeInfoList[0],
        labelWidth: '140px'
      }"
      :showBtns="false"
      class="formData newFormData"
      ref="repeatSchemeFormData"
      @iconClick="iconClick"
      v-if="index === '03'"
    >
    </base-form>
  </div>
</template>
<script>
import { parameterApi } from '@/api/parameterApi'
import { newSchemeInfo, repeatSchemeInfo } from '../utils/config'
import { contactApi } from '@/api/contactApi'
import baseForm from '@/components/common/base-form/base-form.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
export default {
  components: { baseForm, BaseButton },
  name: 'financingPlan',
  props: {
    dictType: String, // 产品类型
    formData: Object,
    schemeInfoList: Array,
    pledgorList: Array, // 质押信息
    businessId: String,
    detailData: Object, // 详情数据
    mainPartData: Array,
    payeeData: Array,
    index: String
  },
  data () {
    return {
      personsDatas: [], // 主借人数据
      providertData: [] // 资金方数据
    }
  },
  computed: {
    repeatSchemeInfo () {
      return function (index) {
        return repeatSchemeInfo(this, index)
      }
    },
    schemeInfoForm () {
      return function (index) {
        return newSchemeInfo(this, index)
      }
    }
  },
  watch: {
    schemeInfoList: {
      handler (val) {
        if (this.index === '03') {
          this.$nextTick(() => {
            this.$refs.repeatSchemeFormData.clearValidate()
          })
        }
      },
      deep: true
    }
  },
  created () {
    this.providertInfo()
  },
  methods: {
    iconClick () {
      console.log(888)
    },
    // 重置股东会决议
    clearShareholderMeeting () {
      this.$emit('clearShareholderMeeting', '')
    },
    // 查询共借人
    async companys (financeType, type) {
      this.$nextTick(() => {
        this.$refs.schemeFormData.forEach((ele) => {
          ele.clearValidate()
        })
      })
      const res = await contactApi.companys({
        businessId: this.businessId,
        financeType: financeType
      })
      if (res.success) {
        this.personsDatas = res.data
        if (type === 'detail') {
          for (const index in this.schemeInfoList) {
            const ele = this.schemeInfoList[index]
            for (const i in this.personsDatas) {
              const eles = this.personsDatas[i]
              if (eles.keyId === ele.mainBorrowerId) {
                ele.creditCode = JSON.parse(JSON.stringify(eles.creditCode))
                let status = true
                this.pledgorList.forEach(item => {
                  if (item.name === eles.enterpriseName) {
                    status = false
                  }
                })
                if (status) {
                  const { data } = await contactApi.contractList({
                    businessId: this.businessId,
                    mainBorrowerId: eles.keyId
                  })
                  this.$set(this.pledgorList, this.pledgorList.length, {
                    name: eles.enterpriseName,
                    creditCode: eles.creditCode,
                    gysId: eles.keyId,
                    contractData: data
                  })
                  // this.pledgorList.push({ name: eles.enterpriseName, creditCode: eles.creditCode, gysId: eles.keyId, contractData: data })
                }
                this.getList(eles.keyId, index, 'detail')
              }
            }
          }
          this.setPledgorData(type)
        } else {
          this.setPledgorData(type)
          console.log(this.pledgorList)
        }
      }
    },
    // 设置质押信息
    setPledgorData (type) {
      this.pledgorList.forEach(ele => {
        const arr = []
        if (this.detailData.finContractInfosPlain) {
          this.detailData.finContractInfosPlain.forEach(eles => {
            if (ele.gysId === eles.gysId) {
              arr.push(eles)
            }
          })
        }
        if (type === 'detail') {
          this.$set(ele, 'pledgorData', arr)
        } else {
          if (!ele.pledgorData) {
            this.$set(ele, 'pledgorData', arr)
          }
        }
        ele.pledgorData.forEach(item => {
          // 质押信息
          // item.pledgeValue = item.pledgeValue ? item.pledgeValue : ''
          // item.relLoanAmount = item.relLoanAmount ? item.relLoanAmount : ''
          if (type === 'detail') {
            ele.contractData.forEach(eles => {
              if (eles.keyId === item.finContractId) {
                item.keyIds = eles.keyId
              }
            })
          } else {
            // this.finContractChange(item, item.finContractId, ele.contractData, ele.pledgorData)
          }
        })
      })
    },
    // 获取共借人信息
    getList (id, index, type) {
      const params = {
        enterpriseId: id
      }
      contactApi.getList(params).then(res => {
        if (res.success) {
          this.$set(this.schemeInfoList[index], 'personsData', res.data)
          if (type === 'detail') {
            const ele = this.schemeInfoList[index]
            this.schemeInfoList[index].personsData.forEach(eles => {
              if (eles.personnerName === ele.coBorrower) {
                ele.idCard = JSON.parse(JSON.stringify(eles.idCard))
              }
            })
          }
        }
      })
    },

    // 选取主借人
    async personsChange (data, arr, index) {
      this.$emit('borrowerChange', data, arr, index)
      let pledgorState = true
      this.pledgorList.forEach(ele => {
        if (ele.gysId === data) {
          pledgorState = false
        }
      })
      if (
        (this.detailData.finBusuinessInfo.gysId === data || !pledgorState) &&
        this.formData.financeType === 'SECOND_MODEL'
      ) {
        if (!pledgorState) {
          this.pledgorList.splice(index + 1, 1)
        }
        this.$set(this.schemeInfoList[index], 'mainBorrowerId', '')
        this.$set(this.schemeInfoList[index], 'mainBorrower', '')
        this.$set(this.schemeInfoList[index], 'creditCode', '')
        this.$set(this.schemeInfoList[index], 'coBorrowerId', '')
        this.$set(this.schemeInfoList[index], 'coBorrower', '')
        this.$set(this.schemeInfoList[index], 'idCard', '')
        this.$nextTick(() => {
          this.$refs.schemeFormData[index].clearValidate([
            'coBorrowerId',
            'idCard'
          ])
        })
        return this.warning('借款人不能重复')
      }
      await this.personsDatas.forEach(async ele => {
        if (ele.keyId === data) {
          this.$set(this.schemeInfoList[index], 'creditCode', ele.creditCode)
          this.$set(
            this.schemeInfoList[index],
            'mainBorrower',
            ele.enterpriseName
          )
          // 清空共借人数据
          this.$set(this.schemeInfoList[index], 'coBorrowerId', '')
          this.$set(this.schemeInfoList[index], 'coBorrower', '')
          this.$set(this.schemeInfoList[index], 'idCard', '')
          let status = true
          this.pledgorList.forEach(item => {
            if (item.name === ele.enterpriseName) {
              status = false
            }
          })
          const { data } = await contactApi.contractList({
            businessId: this.businessId,
            mainBorrowerId: ele.keyId
          })
          if (status) {
            if (this.pledgorList.length >= index + 1) {
              this.$set(this.pledgorList, index + 1, {
                name: ele.enterpriseName,
                creditCode: ele.creditCode,
                gysId: ele.keyId,
                contractData: data,
                pledgorData: []
              })
              // this.pledgorList[index + 1] = { name: ele.enterpriseName, creditCode: ele.creditCode, gysId: ele.keyId, contractData: data, pledgorData: [] }
            } else {
              this.$set(this.pledgorList, this.pledgorList.length, {
                name: ele.enterpriseName,
                creditCode: ele.creditCode,
                gysId: ele.keyId,
                contractData: data,
                pledgorData: []
              })
              // this.pledgorList.push({ name: ele.enterpriseName, creditCode: ele.creditCode, gysId: ele.keyId, contractData: data, pledgorData: [] })
            }
          } else {
            this.$set(this.pledgorList[index], 'contractData', data)
          }
          this.getList(ele.keyId, index)
        }
      })
      if (data === '') {
        this.$set(this.schemeInfoList[index], 'creditCode', '')
        this.$set(this.schemeInfoList[index], 'mainBorrower', '')
        // 清空共借人数据
        this.$set(this.schemeInfoList[index], 'coBorrowerId', '')
        this.$set(this.schemeInfoList[index], 'coBorrower', '')
        this.$set(this.schemeInfoList[index], 'idCard', '')
        this.schemeInfoList[index].personsData = []
        this.pledgorList.splice(index + 1, 1)
        if (this.detailData.finContractInfosPlain) {
          this.detailData.finContractInfosPlain.splice(index + 1, 1)
        }
      }
      this.$nextTick(() => {
        this.$refs.schemeFormData[index].clearValidate([
          'coBorrowerId',
          'idCard'
        ])
      })
    },
    // 选取共借人
    personnerChange (data, arr, index) {
      this.schemeInfoList[index].personsData.forEach(ele => {
        if (ele.personKeyId === data) {
          this.schemeInfoList[index].idCard = JSON.parse(
            JSON.stringify(ele.idCard)
          )
          this.schemeInfoList[index].coBorrower = JSON.parse(
            JSON.stringify(ele.personnerName)
          )
        }
      })
      if (data === '') {
        this.schemeInfoList[index].idCard = ''
        this.schemeInfoList[index].coBorrower = ''
      }
    },
    // 获取资金方信息
    providertInfo () {
      const params = {
        // capitalSideType: 4, // 4 银行
        replyStatus: 1 // 审核状态
      }
      parameterApi.getFundsprovidertInfo(params).then(res => {
        if (res.data) {
          this.providertData = res.data
          res.data.forEach(ele => {
            const status = true

            this.providertData.forEach(eles => {
              // if (ele.keyId === eles.keyId) {
              //   status = false
              // }
            })
            if (this.providertData.length === 0 || status) {
              this.providertData.push(ele)
            }
          })
          this.providertData.forEach(ele => {
            this.$set(
              ele,
              'capitalAndProductName',
              ele.capitalSideName + '-' + ele.productName
            )
          })
          // this.deleteCapital()
        }
      })
    },
    // 融资利率赋值
    // setproductRate (productRate, index) {
    //   this.$set(this.schemeInfoList[index], 'financeRate', productRate)
    //   this.schemeInfoList.forEach((ele, i) => { // 二级模式下控制资金方统一
    //     if (i !== index) {
    //       this.$set(ele, 'capitalSideId', this.schemeInfoList[index].capitalSideId)
    //       this.$set(ele, 'capitalSideName', this.schemeInfoList[index].capitalSideName)
    //       this.$set(ele, 'surplusQuota', this.schemeInfoList[index].surplusQuota)
    //       this.$set(ele, 'capitalSideProductId', this.schemeInfoList[index].capitalSideProductId)
    //       this.$set(ele, 'financeRate', this.schemeInfoList[index].financeRate)
    //     }
    //   })
    // },
    // 获取资金方融资利率
    // productRate (name, index) {
    //   if (!name) {
    //     this.$set(this.schemeInfoList[index], 'financeRate', '')
    //     this.schemeInfoList.forEach((ele, i) => { // 二级模式下控制资金方统一
    //       if (i !== index) {
    //         this.$set(ele, 'capitalSideId', '')
    //         this.$set(ele, 'capitalSideName', '')
    //         this.$set(ele, 'surplusQuota', '')
    //         this.$set(ele, 'capitalSideProductId', '')
    //         this.$set(ele, 'financeRate', '')
    //       }
    //     })
    //   } else {
    //     const params = {
    //       capitalSideName: name, // 4 银行
    //       capitalSideType: 4
    //     }
    //     parameterApi.getFundsprovidertInfo(params)
    //       .then(res => {
    //         if (res.data) {
    //           this.$set(this.schemeInfoList[index], 'financeRate', res.data.length > 0 ? res.data[0].productRate : '')
    //           this.schemeInfoList.forEach((ele, i) => { // 二级模式下控制资金方统一
    //             if (i !== index) {
    //               this.$set(ele, 'capitalSideId', this.schemeInfoList[index].capitalSideId)
    //               this.$set(ele, 'capitalSideName', this.schemeInfoList[index].capitalSideName)
    //               this.$set(ele, 'surplusQuota', this.schemeInfoList[index].surplusQuota)
    //               this.$set(ele, 'capitalSideProductId', this.schemeInfoList[index].capitalSideProductId)
    //               this.$set(ele, 'financeRate', this.schemeInfoList[index].financeRate)
    //             }
    //           })
    //         }
    //       })
    //   }
    // },
    // 添加借款人
    addSchemeInfoList () {
      const obj = {
        serviceRateStr: '',
        serviceRateState: false,
        repayTypeCode: '0',
        financeAmount: '',
        finRepayStyleDTO: {
          advanceRepayTerm: '', // 提前还款期限
          businessId: '', // 业务ID
          chargeParty: '', // 收费主体
          finPlanId: '', // 融资方案ID
          repayAmount: '', // 还款金额
          repayRate: '', // 还款利率
          repayStatus: '0', // 还款方式
          repayTime: '', // 还款时间(多个时间用逗号隔开)
          repayType: '' // 还款方式（ 1按照每月金额 2按照固定利率）
        }
      }

      if (this.schemeInfoList[0]) {
        const setObj = {
          capitalSideId: this.schemeInfoList[0].capitalSideId,
          capitalSideName: this.schemeInfoList[0].capitalSideName,
          capitalNameAndProductName: this.schemeInfoList[0]
            .capitalNameAndProductName,
          surplusQuota: this.schemeInfoList[0].surplusQuota,
          capitalSideProductName: this.schemeInfoList[0].capitalSideProductName,
          capitalSideProductId: this.schemeInfoList[0].capitalSideProductId,
          financeRate: this.schemeInfoList[0].financeRate,
          serviceRateStr: this.schemeInfoList[0].serviceRateStr,
          serviceRateState: this.schemeInfoList[0].serviceRateState,
          financeTerm: this.schemeInfoList[0].financeTerm,
          loanAssistanceOrg: this.schemeInfoList[0].loanAssistanceOrg,
          loanAssistanceOrgId: this.schemeInfoList[0].loanAssistanceOrgId,
          finServiceCollectInfo: this.schemeInfoList[0].finServiceCollectInfo
        }
        this.schemeInfoList.push(Object.assign({}, obj, setObj))
        this.$emit('addSecondBorrower', this.schemeInfoList)
      }
    },
    // 删除借款人
    removeSchemeInfo (index) {
      // const id = this.schemeInfoList[index]
      this.schemeInfoList.splice(index, 1)
      this.pledgorList.splice(index + 1, 1)
      this.$emit('delSecondBorrower', this.schemeInfoList, index)
    },
    // 保存服务费率
    serviceTariffing (data) {
      const arr = []
      data.data.forEach(ele => {
        arr.push(ele.chargeRate + '%(' + ele.chargeParty + ')')
      })
      this.schemeInfoList[data.key].serviceRateStr = arr
        .join(',')
        .replace(/,/g, ';')
      this.schemeInfoList[data.key].finServiceCollectInfo = data.data
      if (data.data.length === 0) {
        this.schemeInfoList[data.key].serviceRateState = false
      } else {
        this.schemeInfoList[data.key].serviceRateState = true
      }
      this.schemeInfoList.forEach((ele, i) => {
        // 控制二级模式下服务费率统一
        if (i !== data.key) {
          this.$set(
            ele,
            'serviceRateStr',
            this.schemeInfoList[data.key].serviceRateStr
          )
          this.$set(
            ele,
            'finServiceCollectInfo',
            this.schemeInfoList[data.key].finServiceCollectInfo
          )
          this.$set(
            ele,
            'serviceRateState',
            this.schemeInfoList[data.key].serviceRateState
          )
          this.$refs.schemeFormData[data.key].clearValidate()
        }
      })
    },
    // 分批还款方式
    repaymentType (data) {
      this.schemeInfoList[data.key].finRepayStyleDTO = data.data
      // 中小担 需重置还款计划
      if (this.dictType === '4') {
        this.$emit('clearRepaymentPlan', '')
      }
    }
  }
}
</script>
<style lang="scss" src="../index.scss" scoped></style>
<style>
.financingPlan {
  padding: 10px;
}
</style>
