var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"financingPlan"},[_c('div',{staticClass:"borrowerContent"},[(
        _vm.formData.financeType &&
          _vm.formData.financeType != 'ONCE_MODEL' &&
          _vm.index === '02'
      )?_c('base-button',{staticClass:"addBorrower",attrs:{"label":"添加借款人","icon":"el-icon-plus"},on:{"click":_vm.addSchemeInfoList}}):_vm._e()],1),(_vm.index === '02')?_c('div',_vm._l((_vm.schemeInfoList),function(item,index){return _c('div',{key:index,staticClass:"scheme"},[(_vm.schemeInfoList.length > 1)?_c('div',{staticClass:"del",on:{"click":function($event){return _vm.removeSchemeInfo(index)}}},[_c('i',{staticClass:"iconfont iconshanchu1"})]):_vm._e(),_c('base-form',{ref:"schemeFormData",refInFor:true,staticClass:"formData newFormData",attrs:{"componentList":_vm.schemeInfoForm(index),"formAttrs":{
          model: item,
          labelWidth: '140px'
        },"keyIndex":index,"showBtns":false}})],1)}),0):_vm._e(),(_vm.index === '03')?_c('base-form',{ref:"repeatSchemeFormData",staticClass:"formData newFormData",attrs:{"componentList":_vm.repeatSchemeInfo(0),"formAttrs":{
      model: _vm.schemeInfoList[0],
      labelWidth: '140px'
    },"showBtns":false},on:{"iconClick":_vm.iconClick}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }