<template>
  <!-- 其他备注模块 -->
  <div class="otherNote">
    <base-form
      :componentList="otherNoteForm"
      :formAttrs="{
        model: formData,
        labelWidth: '100px',
        disabled: isDetail
      }"
      :showBtns="false"
      class="formData"
      ref="otherFrom"
    >
    </base-form>
    <!-- 抵质押物 -->
    <!-- v-if="dictType === '4'" -->
    <div >
      <title-center name="抵质押物" :rules="false"></title-center>
      <div v-if="!isDetail">
        <div class="addbtn">
          <div class="addbutton" @click="add">
            <i class="el-icon-plus"></i>
          </div>
        </div>
      </div>
      <base-table
        :columns="pledgeConfig"
        :tableAttrs="{
          data: finPawnInfos,
          stripe: true
        }"
        :isCaculateHeight="false"
        :showPage="false"
      >
        <template slot="index" slot-scope="scope">{{
          scope.$index + 1
        }}</template>
        <template slot="pawnName" slot-scope="scope" v-if="!isDetail">
          <base-input
            type="textarea"
            placeholder="请输入"
            v-model="scope.row.pawnName"
            rows="5"
            maxlength="2000"
          ></base-input
        ></template>
        <template slot="pawnValue" slot-scope="scope" v-if="!isDetail">
          <base-input
            type="textarea"
            placeholder="请输入"
            v-model="scope.row.pawnValue"
            rows="5"
            maxlength="2000"
          ></base-input>
        </template>
        <template slot="action" slot-scope="scope" v-if="!isDetail">
          <icon-button
            @click="remove(scope.$index)"
            content="删除"
            icon="iconfont iconshanchu1"
          />
        </template>
      </base-table>
    </div>
    <!-- 还款计划 -->
    <!-- <div v-if="dictType === '4'">
      <title-center name="还款计划" :rules="true"></title-center>
      <div class="otherNotePlan">
        <base-button label="生成"  icon="iconfont iconshengcheng" @click="generateRepaymentPlan" />
      </div>
       <base-table
        :columns="repaymentPlanConfig"
        :tableAttrs="{
          data: finRepayPlan,
          stripe: true,
        }"
        :isCaculateHeight="false"
        :showPage="false"
      >
      <template slot="index" slot-scope="scope">{{scope.$index+1}} </template>
      <template slot="contractRepaymentArr" slot-scope="scope">
        <base-select
        v-model="scope.row.contractRepayment"
        :clearable="true"
        :multiple='true'
        :options="repaymentPlanContract"
        :selectedField="['keyId', 'contractName']"
        @change="changeContract(scope.row.contractRepayment,scope.$index)"
       ></base-select>
      </template>
      <template slot="repayCondition" slot-scope="scope">
        <base-input v-model="scope.row.repayCondition" maxlength='500' placeholder="请选择还款合同"></base-input>
      </template>
      </base-table>
    </div> -->
    <title-center name="放款前置条件" :rules="true"></title-center>
    <base-input
      type="textarea"
      placeholder="请输入"
      v-model="lendingBeforeConditionData"
      rows="7"
      style="margin-top:10px"
      :disabled="isDetail"
    ></base-input>
    <title-center name="放款后落实安排" :rules="false"></title-center>
    <base-input
      type="textarea"
      :placeholder='placeholder1'
      v-model="lendingAfterArrangeData"
      rows="5"
      style="margin-top:10px"
      :disabled="isDetail"
    ></base-input>
    <!-- <title-center name="已控制关联资金路径的其他账户" :rules="false"></title-center>
      <div v-if="!isDetail">
        <div class="addbtn">
          <div class="addbutton" @click="addAccount">
            <i class="el-icon-plus"></i>
          </div>
        </div>
      </div>
      <base-table
        :columns="accountDataConfig"
        :tableAttrs="{
          data: finResultInfoAssociatedAccountVOList,
          stripe: true
        }"
        :isCaculateHeight="false"
        :showPage="false"
      >
        <template slot="index" slot-scope="scope">{{
          scope.$index + 1
        }}</template>
        <template slot="factoringAccountBank" slot-scope="scope" v-if="!isDetail">
          <base-input

            placeholder="请输入"
            v-model="scope.row.factoringAccountBank"

          ></base-input
        ></template>
        <template slot="factoringAccountName" slot-scope="scope" v-if="!isDetail">
          <base-input

            placeholder="请输入"
            v-model="scope.row.factoringAccountName"

          ></base-input>
        </template>
        <template slot="factoringAccountNumber" slot-scope="scope" v-if="!isDetail">
          <base-input

            placeholder="请输入"
            v-model="scope.row.factoringAccountNumber"
               @input="
                        scope.row.factoringAccountNumber = scope.row.factoringAccountNumber.replace(
                          /[^\d]/g,
                          ''
                        )
                      "

          ></base-input>
        </template>
        <template slot="action" slot-scope="scope" v-if="!isDetail">
          <icon-button
            @click="removeAccountData(scope.$index)"
            content="删除"
            icon="iconfont iconshanchu1"
          />
        </template>
      </base-table> -->
     <title-center name="备注" :rules="false"></title-center>
     <base-input
      type="textarea"
      placeholder="请输入"
      v-model="remarkData"
      rows="5"
      :maxlength='1000'
      style="margin-top:10px"
      :disabled="isDetail"
    ></base-input>
  </div>
</template>
<script>
import BaseInput from '@/components/input/sz-input/sz-input.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { formatDate } from '@/utils/auth/common'
import TitleCenter from '../../components/title-center.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { otherNote, pledgeConfig, repaymentPlanConfig, accountDataConfig } from '../utils/config'
import BaseForm from '@/components/common/base-form/base-form.vue'
export default {
  components: { BaseForm, BaseTable, TitleCenter, BaseInput, IconButton },
  name: 'otherNote',
  props: {
    lendingBeforeCondition: String,
    lendingAfterArrange: String,
    otherNoteRemark: String,
    formData: Object,
    dictType: String,
    finRepayPlan: Array,
    repaymentPlanContract: Array, // 已选择的合同
    finPawnInfos: Array, // 抵质押物数据
    finResultInfoAssociatedAccountVOList: Array, // 已控制关联资金路径的其他账户数据
    isDetail: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      commitmentLetter: '',
      placeholder1: '请输入：' + '\n' + '【示例：7日内获取最新详版《征信报告》等】',
      accountData: [{}, {}]
    }
  },
  watch: {
    remarkData (val) {
      console.log(val)
      this.$emit('update:otherNoteRemark', val)
    }
  },
  computed: {
    remarkData: {
      get () {
        return this.otherNoteRemark
      },
      set (val) {
        this.$emit('update:otherNoteRemark', val)
      }
    },
    lendingBeforeConditionData: {
      get () {
        return this.lendingBeforeCondition
      },
      set (val) {
        this.$emit('update:lendingBeforeCondition', val)
      }
    },
    lendingAfterArrangeData: {
      get () {
        return this.lendingAfterArrange
      },
      set (val) {
        this.$emit('update:lendingAfterArrange', val)
      }
    },
    accountDataConfig () {
      return accountDataConfig(this)
    },
    repaymentPlanConfig () {
      return repaymentPlanConfig(this)
    },
    pledgeConfig () {
      return pledgeConfig(this)
    },
    otherNoteForm () {
      return otherNote(this)
    }
  },
  methods: {
    // 新增其他账户
    addAccount () {
      this.finResultInfoAssociatedAccountVOList.push({})
    },
    // 选择合同
    changeContract (data, index) {
      let value = ''
      const repaymentPlanContractArr = []
      data.forEach(elem => {
        // elem 合同id
        this.repaymentPlanContract.forEach(item => {
          if (item.keyId === elem) {
            if (repaymentPlanContractArr.length === 0) {
              repaymentPlanContractArr.push(
                this.finRepayPlan[index].isDate
                  ? this.finRepayPlan[index].preRepayDate +
                      '/' +
                      item.contractName
                  : formatDate(
                    this.finRepayPlan[index].preRepayDate,
                    'YY/MM/DD'
                  ) +
                      '/' +
                      item.contractName
              )
            } else {
              repaymentPlanContractArr.push(item.contractName)
            }
          }
        })
      })
      value = repaymentPlanContractArr.join(',')
      this.$set(this.finRepayPlan[index], 'repayContractId', data.join(','))
      this.$set(this.finRepayPlan[index], 'repayCondition', value)
    },
    // 生成
    generateRepaymentPlan () {
      this.$emit('generateRepaymentPlan', '')
    },
    remove (index) {
      this.finPawnInfos.splice(index, 1)
    },
    removeAccountData (index) {
      this.finResultInfoAssociatedAccountVOList.splice(index, 1)
    },
    add () {
      this.finPawnInfos.push({})
    }
  }
}
</script>
<style lang="scss" src="../index.scss" scoped></style>
